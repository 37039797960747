import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Check, ExpandMore, ExpandLess } from '@mui/icons-material';

import { ClinicInsurancesProps } from './models';
import {
  Wrapper,
  InsurancesList,
  InsuranceStyled,
  ShowMoreButton,
} from './styles';

const ClinicInsurances: FC<ClinicInsurancesProps> = ({ insurances }) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  const filteredInsurancesList = useMemo(() => {
    return insurances.filter((item, index) => {
      if (showMore) {
        return item;
      } else if (index < 8) {
        return item;
      }
    });
  }, [insurances, showMore]);

  const onShowMore = () => {
    setShowMore((prev) => !prev);
  };

  return (
    <Wrapper>
      <h1>{t('doctor_profile.clinic_insurances')}</h1>
      <InsurancesList>
        {filteredInsurancesList.map((insurance) => (
          <InsuranceStyled key={insurance.id}>
            <Check />
            <p>{insurance.name}</p>
          </InsuranceStyled>
        ))}
        {insurances.length > 8 && (
          <ShowMoreButton type="button" onClick={onShowMore}>
            {showMore ? (
              <>
                {t('global.show_less')}
                <ExpandLess />
              </>
            ) : (
              <>
                {t('global.show_more')}
                <ExpandMore />
              </>
            )}
          </ShowMoreButton>
        )}
      </InsurancesList>
    </Wrapper>
  );
};

export default ClinicInsurances;
