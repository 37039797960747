import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { CountriesAPI } from 'integration/api/countries';

import { RequestEnum } from '../request/model';

const getSupportedCountries = createAsyncThunk(
  RequestEnum.getSupportedCountries,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await CountriesAPI.getSupportedCountries();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

const setUserCountry = createAsyncThunk(
  RequestEnum.setUserCountry,
  async (params: { countryIso: string }, { rejectWithValue }) => {
    try {
      const { data } = await CountriesAPI.setUserCountry(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const CountryActions = { getSupportedCountries, setUserCountry };
