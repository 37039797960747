import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const RadioButtonStyled = styled.div`
  display: flex;
  align-items: center;

  > label {
    cursor: pointer;
    color: ${commonColors.grey700};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    margin-left: 8px;
  }

  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: ${commonColors.white};
    margin: 0;

    color: currentColor;
    width: 20px;
    height: 20px;
    border: 1px solid ${commonColors.grey400};
    border-radius: 50%;
    display: grid;
    place-content: center;
    cursor: pointer;
  }

  input[type='radio']::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 12px 12px ${commonColors.blue500};
    border-radius: 100px;
    background: ${commonColors.white};
  }

  input[type='radio']:checked {
    border: 1px solid ${commonColors.blue500};
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }
`;
