import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface TabStyledProps {
  active: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid ${commonColors.grey200};
  background: ${commonColors.white};
`;

export const Info = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;

  > button {
    min-width: fit-content;
    width: fit-content;
    height: 38px;
    padding: 8px 20px;
  }
`;

export const DoctorInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const TopSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;

    h2 {
      color: ${commonColors.grey900};
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }

    p {
      color: ${commonColors.grey900};
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0.07px;
    }
  }
`;

export const ClinicInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TabsStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid ${commonColors.grey200};
`;

export const TabStyled = styled.button<TabStyledProps>`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  position: relative;
  bottom: -1px;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-bottom: ${({ active }) =>
    `2px solid ${active ? commonColors.grey700 : 'transparent'}`};

  p {
    color: ${({ active }) =>
      active ? commonColors.grey900 : commonColors.grey600};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;

export const ClinicDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px 0 12px;

  > div {
    display: flex;
    align-items: center;
    gap: 4px;

    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;

      path {
        fill: ${commonColors.grey600};
      }
    }

    p {
      color: ${commonColors.grey900};
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.07px;
    }
  }
`;

export const SubscriptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  h2 {
    color: ${commonColors.grey900};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.07px;
  }
`;

export const SubscriptionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid ${commonColors.grey200};
  background: ${commonColors.grey50};

  ul {
    padding-left: 20px;
    list-style: disc;

    li {
      color: ${commonColors.grey800};
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0.07px;

      span {
        font-weight: 400;
        &:first-of-type {
          margin-left: 8px;
        }
      }
    }
  }
`;

export const AvatarStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: ${commonColors.blueDark100};
  width: 84px;
  min-width: 84px;
  height: 84px;
  min-height: 84px;

  img {
    width: 84px;
    height: 84px;
    border-radius: 8px;
  }

  svg {
    width: 84px;
    height: 60px;
  }
`;
