import React, { FC } from 'react';

import { EmployeeItemProps } from './modules';
import { StyledTr } from './styles';

const EmployeeItem: FC<EmployeeItemProps> = ({ employee }) => {
  return (
    <>
      <StyledTr>
        <td>
          {employee.firstName} {employee.lastName}
        </td>
        <td>{employee.phone}</td>
        <td>{employee.department?.name || ''}</td>
        <td>{employee.jobPosition}</td>
      </StyledTr>
    </>
  );
};

export default EmployeeItem;
