import * as yup from 'yup';

export interface QuickSearchFromData {
  searchEntity: { id: string; name: string };
  address: { id?: string; name?: string; city?: string };
  employees: { minNumberOfEmployees: number; maxNumberOfEmployees: number };
}

export type QuickTypes =
  | 'address'
  | 'employees'
  | 'searchEntity'
  | 'address.name'
  | 'address.id'
  | 'address.city'
  | 'employees.minNumberOfEmployees'
  | 'employees.maxNumberOfEmployees'
  | 'searchEntity.name'
  | 'searchEntity.id';

export const useQuickSearchSchema = () => {
  return yup.object({
    searchEntity: yup
      .object({
        id: yup.string().required(),
        name: yup.string().required(),
      })
      .required(),
    address: yup.object({
      id: yup.string(),
      name: yup.string(),
      city: yup.string(),
    }),
    employees: yup.object({
      minNumberOfEmployees: yup.number(),
      maxNumberOfEmployees: yup.number(),
    }),
  });
};
