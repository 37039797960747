import styled from '@emotion/styled';
import { commonColors } from 'application/theme';
import { PurchasedSubscriptionStatus } from 'features/doctor-subscription/constants';

interface StatusStyledProps {
  status: string;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid ${commonColors.grey300};
  background: ${commonColors.white};
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);
`;

export const TopSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
`;

export const PhotoStyled = styled('div')`
  display: flex;
  align-items: center;

  > img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
`;

export const AvatarStyled = styled('div')`
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  background: ${commonColors.blueDark100};
  border-radius: 50%;

  > svg {
    width: 32px;
    height: 32px;
    path {
      fill: ${commonColors.grey900};
    }
  }
`;

export const NameStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: inherit;

  h1 {
    color: ${commonColors.grey900};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.2px;
  }

  p {
    color: ${commonColors.grey700};
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0.09px;
  }
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: fit-content;
  gap: 12px;

  p {
    color: ${commonColors.error2};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.07px;
  }
`;

export const StatusStyled = styled.div<StatusStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  border-radius: 100px;
  background-color: ${({ status }) =>
    status === PurchasedSubscriptionStatus.Active
      ? commonColors.success4
      : commonColors.errorBackground};

  p {
    color: ${({ status }) =>
      status === PurchasedSubscriptionStatus.Active
        ? commonColors.success1
        : commonColors.error2};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0.07px;
  }
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SubscriptionInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  column-gap: 32px;
  row-gap: 8px;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;

    color: ${commonColors.grey700};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.08px;

    p {
      font-weight: 500;
    }

    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;

      path {
        fill: ${commonColors.grey600};
      }

      &.stroke {
        path {
          stroke: ${commonColors.grey600};
          fill: unset;
        }
      }
    }
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
`;

export const CancelButton = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  color: ${commonColors.error2};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.08px;
`;

export const ButtonStyled = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 4px;

  p {
    color: ${commonColors.blue800};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.08px;
  }

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;

    path {
      fill: ${commonColors.blue800};
    }
  }
`;
