import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Person } from '@mui/icons-material';
import {
  ProfessionalBriefResponseDto,
  SupportedLanguageIso,
} from '@docbay/schemas';

import { Input } from 'application/components';
import { SearchIcon } from 'application/assets';
import { useLocalizeKey } from 'application/hooks/useLocalizeKey';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { useDebounce } from 'application/hooks/useDebounce';
import { useLanguage } from 'application/hooks/useLanguage';
import { HrDepartmentSearchActions } from 'application/store/reducers/HrDepartmentSearch/actions';
import useOnClickOutside from 'application/hooks/useOnClickOutside';
import { resetQuickSearch } from 'application/store/reducers/HrDepartmentSearch/hrDepartmentSearchSlice';
import { PathNames } from 'application/routes/constants';

import { QuickSearchProps } from './models';
import {
  Wrapper,
  OptionsList,
  OptionItem,
  SpecializationStyled,
  DividerStyled,
  NameStyled,
  AvatarStyled,
} from './styles';

const QuickSearch: FC<QuickSearchProps> = ({
  value,
  onChange,
  onClear,
  errorMessage,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentUserCountry } = useAppSelector((state) => state.countries);
  const { quickSearch } = useAppSelector((state) => state.hrDepartmentSearch);

  const [isActive, setIsActive] = useState(false);
  const [search, setSearch] = useState('');
  const searchItem = useDebounce(search, 300);

  const { currentLanguage } = useLanguage();
  const { localizeNameKey } = useLocalizeKey();

  const isListNotEmpty = useMemo(() => {
    return (
      isActive &&
      (quickSearch?.professionals?.length ||
        quickSearch?.specializations?.length)
    );
  }, [isActive, quickSearch]);

  useOnClickOutside(ref, () => {
    setIsActive(false);
  });

  useEffect(() => {
    if (value?.name !== search) {
      setSearch(value?.name || '');
    }
  }, [value]);

  const handleQuickSearch = async () => {
    await dispatch(
      HrDepartmentSearchActions.quickSearch({
        text: searchItem,
        languageIso: currentLanguage.toLowerCase() as SupportedLanguageIso,
        countryCode: currentUserCountry,
      }),
    );
  };

  useEffect(() => {
    if (searchItem) {
      handleQuickSearch();
    } else {
      dispatch(resetQuickSearch());
    }
  }, [searchItem]);

  const goToProfessionalProfile = (id: string) => {
    navigate(`${PathNames.doctorProfile}/${id}`);
  };

  const handleChangeValue = (id: string, name: string) => {
    setSearch(name);
    onChange({
      id: id,
      name: name,
    });
    setIsActive(false);
  };

  const getProfessionalName = (item: ProfessionalBriefResponseDto) => {
    const prefix =
      item.gender === 'Male' ? t('global.drMale') : t('global.drFemale');

    return `${prefix} ${item.firstName + ' ' + item.lastName}`;
  };

  const handleClear = () => {
    setSearch('');
    onClear();
  };

  return (
    <Wrapper ref={ref} hasError={!!errorMessage}>
      <div onMouseDown={() => setIsActive(true)}>
        <Input
          id="search"
          type="search"
          placeholder={t('find_doctor.search_group.search_entity_placeholder')}
          errorMessage={errorMessage}
          hideErrorMessage={true}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onClear={handleClear}
          autoComplete="off"
        />
      </div>
      {isListNotEmpty && (
        <OptionsList>
          {quickSearch?.specializations.map((item) => (
            <OptionItem
              key={item.id}
              onClick={() => {
                handleChangeValue(item.id || '', item[localizeNameKey]);
              }}
              selected={value?.name === String(item[localizeNameKey])}
            >
              <SpecializationStyled>
                <SearchIcon />
                <span>{item[localizeNameKey]}</span>
              </SpecializationStyled>
            </OptionItem>
          ))}
          {quickSearch?.specializations?.length &&
          quickSearch?.professionals?.length ? (
            <DividerStyled />
          ) : null}
          {quickSearch?.professionals.map((item) => (
            <OptionItem
              key={item.id}
              onClick={() => goToProfessionalProfile(String(item.id))}
              selected={value?.name === String(item.firstName)}
            >
              <NameStyled>
                {item.mainPhoto ? (
                  <img
                    src={item.mainPhoto.thumbnailUrl}
                    alt={item.mainPhoto.id}
                    width={32}
                    height={32}
                  />
                ) : (
                  <AvatarStyled>
                    <Person />
                  </AvatarStyled>
                )}
                <div>
                  <span>{getProfessionalName(item)}</span>
                </div>
              </NameStyled>
            </OptionItem>
          ))}
        </OptionsList>
      )}
    </Wrapper>
  );
};

export default QuickSearch;
