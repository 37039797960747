import { createSlice } from '@reduxjs/toolkit';
import { LocationDto } from '@docbay/schemas';

import { MapBoxSuggest } from 'application/types/mapBox';

import { RequestEnum } from '../request/model';

import { MapBoxActions } from './actions';

interface InitialState {
  error: string;
  suggestions: MapBoxSuggest[];
  location: LocationDto;
  region: { name: string; id: string } | null;
  isLoading: boolean;
}

const initialState: InitialState = {
  suggestions: [],
  error: '',
  location: {} as LocationDto,
  region: null,
  isLoading: false,
};

const isCurrentSliceChanged = (type: string, status: string) => {
  return type.endsWith(`${RequestEnum.fetchLocation}/${status}`);
};

const mapBox = createSlice({
  name: 'mapBox',
  initialState,
  reducers: {
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    resetSuggestions: (state) => {
      state.suggestions = [];
      state.location = {} as LocationDto;
      state.region = null;
    },
  },
  extraReducers: (builder) => {
    // get coordinates
    builder.addCase(MapBoxActions.getCoordinates.pending, (state) => {
      state.error = '';
    });
    builder.addCase(MapBoxActions.getCoordinates.rejected, (state, action) => {
      state.error = action.payload as string;
    });
    builder.addCase(MapBoxActions.getCoordinates.fulfilled, (state, action) => {
      state.location = action.payload;
    });
    // get region by coordinates
    builder.addCase(MapBoxActions.getRegionByCoordinates.pending, (state) => {
      state.error = '';
    });
    builder.addCase(
      MapBoxActions.getRegionByCoordinates.rejected,
      (state, action) => {
        state.error = action.payload as string;
      },
    );
    builder.addCase(
      MapBoxActions.getRegionByCoordinates.fulfilled,
      (state, action) => {
        state.region = action.payload;
      },
    );

    builder.addMatcher(
      (action) => isCurrentSliceChanged(action.type, 'pending'),
      (state) => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      (action) => isCurrentSliceChanged(action.type, 'rejected'),
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
    builder.addMatcher(
      (action) => isCurrentSliceChanged(action.type, 'fulfilled'),
      (state, action) => {
        state.isLoading = false;
        state.suggestions = action.payload.suggestions;
      },
    );
  },
});

export const { setLocation, resetSuggestions } = mapBox.actions;

export default mapBox.reducer;
