import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit';

import { ResetStateActionType } from 'application/constants/store';

import { errorSlice } from 'common';

import {
  requestSlice,
  companyRegistrationSlice,
  usersSlice,
  departmentsSlice,
  employeesManagementSlice,
  hrManagementSlice,
  locationsSlice,
  agreementsAndPoliciesSlice,
  mapBoxSlice,
  countriesSlice,
  hrDepartmentSearchSlice,
  specializationsSlice,
  professionalSlice,
  dashboardEmployeesSlice,
  subscriptionsSlice,
} from './reducers';

const reducer = combineReducers({
  error: errorSlice.reducer,
  request: requestSlice,
  companyRegistration: companyRegistrationSlice,
  users: usersSlice,
  departments: departmentsSlice,
  employeesManagement: employeesManagementSlice,
  hrManagement: hrManagementSlice,
  locations: locationsSlice,
  agreementsAndPolicies: agreementsAndPoliciesSlice,
  mapBox: mapBoxSlice,
  countries: countriesSlice,
  hrDepartmentSearch: hrDepartmentSearchSlice,
  specializations: specializationsSlice,
  professional: professionalSlice,
  dashboardEmployees: dashboardEmployeesSlice,
  subscriptions: subscriptionsSlice,
});

const rootReducer: typeof reducer = (
  state: RootState | undefined,
  action: AnyAction,
) => {
  if (action.type === ResetStateActionType) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
