import { styled } from '@mui/system';
import { commonColors } from 'application/theme';

export const MapBoxStyled = styled('div')`
  position: relative;
  width: 100%;

  > section {
    position: absolute;
    z-index: 10;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid ${commonColors.grey300};

    > div {
      max-height: 242px;
      overflow-y: auto;
      display: grid;
      padding: 8px;
      gap: 12px;
      width: 100%;

      background: ${commonColors.white};
      box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
      border-top: 1px solid ${commonColors.grey300};
    }

    button {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      width: 100%;
      background: ${commonColors.white};
      color: ${commonColors.blue800};
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 19.6px;
      letter-spacing: 0.07px;
      cursor: pointer;

      :hover {
        background: ${commonColors.blue50};
      }

      svg {
        width: 20px;
        height: 20px;
        margin-right: 4px;

        path {
          fill: ${commonColors.blue800};
        }
      }
    }
  }
`;

export const LocationItem = styled('div')`
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: ${commonColors.grey100};
  }

  svg {
    path {
      fill: ${commonColors.grey500};
    }
  }

  > div {
    margin-left: 8px;

    > h2 {
      color: ${commonColors.grey800};
      font-size: 16px;
      font-weight: 500;
      line-height: 22.4px;
      letter-spacing: 0.08px;
    }

    > p {
      color: ${commonColors.grey600};
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.035px;
    }
  }
`;
