import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  Dropdown,
  Modal,
  PrimaryButton,
  SecondaryButton,
} from 'application/components';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { Option } from 'application/components/Dropdown/models';
import {
  setAssignToPackage,
  setChosenPackage,
} from 'application/store/reducers/EmployeesManagement/EmployeesManagementSlice';
import { PathNames } from 'application/routes/constants';

import EmployeesTable from '../EmployeesTable';
import EmployeesRange from '../EmployeesRange';

import { Props } from './models';
import {
  ButtonWrapper,
  DividerStyled,
  Title,
  Main,
  PackagesSection,
  Wrapper,
} from './styles';
import { clearPaymentData } from 'application/helpers/sessionStorage/assignEmployees';
import { requestSelector } from 'application/store/reducers/request/selectors';
import { Loader } from 'common';

const AssignEmployee: FC<Props> = ({
  opened,
  subscriptions,
  activeSubscription,
  onClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [active, setActive] = useState<string | null>(null);

  const { fetchEmployees } = useAppSelector(requestSelector);
  const { chosenPackage, assignToPackage } = useAppSelector(
    (state) => state.employeesManagement,
  );

  const isSubmitDisabled = useMemo(() => {
    return (
      assignToPackage!.length < (chosenPackage?.minNumberOfEmployees || 0) ||
      assignToPackage!.length > (chosenPackage?.maxNumberOfEmployees || 0)
    );
  }, [chosenPackage, assignToPackage]);

  const isLoading = fetchEmployees?.loading;

  useEffect(() => {
    setActive(activeSubscription);
  }, [activeSubscription]);

  const employeeLimit = useMemo(() => {
    const currentSubscription = subscriptions.find(
      (subscription) => subscription.id === active,
    );

    return `${t('assign_employees.choose')} ${
      currentSubscription?.minNumberOfEmployees
    } ${t('assign_employees.to')} ${
      currentSubscription?.maxNumberOfEmployees
    } ${t('global.employees')}.`;
  }, [t, active, subscriptions]);

  const subscriptionsOptions = useMemo(() => {
    return subscriptions.map((item) => {
      return {
        label: `${item.title}: (${item.minNumberOfEmployees} - ${
          item.maxNumberOfEmployees
        }, €${item.price} ${t('assign_employees.per_employee')})`,
        value: item.id,
      };
    });
  }, [subscriptions, t]);

  const handleChangeSubscription = (id: string) => {
    setActive(id);

    const currentSubscription = subscriptions.find(
      (subscription) => subscription.id === id,
    );
    dispatch(setChosenPackage(currentSubscription));
  };

  const onSubmit = () => {
    navigate(PathNames.subscriptionPayment);
  };

  const handleCloseModal = () => {
    onClose();
    dispatch(setAssignToPackage([]));
    dispatch(setChosenPackage(null));
    clearPaymentData();
  };

  return (
    <Modal
      opened={opened}
      width={'96vw'}
      padding={'0'}
      height={'96vh'}
      onClose={handleCloseModal}
    >
      {isLoading && <Loader />}
      <Wrapper>
        <Title>{t('assign_employees.title')}</Title>
        <DividerStyled />
        <Main>
          <PackagesSection>
            <Dropdown
              id={'packages'}
              placeholder={t('assign_employees.choose_package')}
              value={active!}
              onChange={(value) => {
                const val = value as Option;
                handleChangeSubscription(val.value as string);
              }}
              options={subscriptionsOptions}
            />
            <div>{employeeLimit}</div>
          </PackagesSection>
          <EmployeesTable />
          <EmployeesRange />
        </Main>

        <ButtonWrapper>
          <DividerStyled />
          <SecondaryButton type="button" onClick={handleCloseModal}>
            {t('global.cancel')}
          </SecondaryButton>
          <PrimaryButton
            type="button"
            disabled={isSubmitDisabled}
            onClick={onSubmit}
          >
            {t('global.continue')}
          </PrimaryButton>
        </ButtonWrapper>
      </Wrapper>
    </Modal>
  );
};

export default AssignEmployee;
