import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > a {
    display: flex;
    align-items: center;
    gap: 4px;

    text-decoration: none;
    color: ${commonColors.blue800};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.08px;

    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;

      path {
        fill: ${commonColors.blue800};
      }
    }
  }
`;

export const ButtonBack = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;

  color: ${commonColors.grey600};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.08px;

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;

    path {
      fill: ${commonColors.grey700};
    }
  }
`;
