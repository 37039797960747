import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'application/hooks/redux';
import { requestSelector } from 'application/store/reducers/request/selectors';
import { Modal, PrimaryButton, SecondaryButton } from 'application/components';
import { CancelIcon } from 'application/assets';

import { CancelSubscriptionModalProps } from './models';
import { ModalStyled, OptionsList, ButtonsSection } from './styles';

const CancelSubscriptionModal: FC<CancelSubscriptionModalProps> = ({
  opened,
  onClose,
  onSubmit,
  subscriptionEndDate,
}) => {
  const { t } = useTranslation();
  const { deleteSubscription } = useAppSelector(requestSelector);

  const isLoading = deleteSubscription.loading;

  const options = [
    t('doctor_subscription.cancel_popup.option_1'),
    t('doctor_subscription.cancel_popup.option_2'),
  ];

  return (
    <Modal opened={opened} onClose={onClose} isLoading={isLoading}>
      <>
        <ModalStyled>
          <h1>{t('doctor_subscription.cancel_popup.title')}</h1>
          <h2>{t('doctor_subscription.cancel_popup.subtitle')}:</h2>
          <OptionsList>
            {options.map((item, index) => (
              <div key={`delete-option-${index}`}>
                <CancelIcon />
                <p>{item}</p>
              </div>
            ))}
          </OptionsList>
          <p>
            *{t('doctor_subscription.cancel_popup.text')} {subscriptionEndDate}
          </p>
        </ModalStyled>
        <ButtonsSection>
          <SecondaryButton type="button" onClick={onClose}>
            {t('global.close')}
          </SecondaryButton>
          <PrimaryButton type="button" styleType={'error'} onClick={onSubmit}>
            {t('doctor_subscription.cancel_subscription')}
          </PrimaryButton>
        </ButtonsSection>
      </>
    </Modal>
  );
};

export default CancelSubscriptionModal;
