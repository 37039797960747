import { useEffect, useState } from 'react';

import { geolocationService } from 'application/helpers/geolocationService';
import { calculateDistance } from 'application/helpers/location';

export const useGeolocation = () => {
  const [userCoordinates, setUserCoordinates] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);

  const [isGeoPermissionsGranted, setIsGeoPermissionsGranted] = useState(false);

  const requestGeoPermissions = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state == 'granted') {
            setIsGeoPermissionsGranted(true);
          } else {
            setIsGeoPermissionsGranted(false);
          }
        });
    } else {
      setIsGeoPermissionsGranted(false);
    }
  };
  useEffect(() => {
    requestGeoPermissions();
  }, []);

  useEffect(() => {
    setUserCoordinates(geolocationService.getGeolocation());
  }, [isGeoPermissionsGranted]);

  const getDistance = ({
    latitude,
    longitude,
  }: {
    latitude?: string;
    longitude?: string;
  }) => {
    if (!userCoordinates?.latitude || !userCoordinates.longitude) {
      return null;
    }
    if (!latitude || !longitude) {
      return null;
    }
    return calculateDistance(
      userCoordinates.latitude,
      userCoordinates.longitude,
      +latitude,
      +longitude,
    );
  };

  return {
    getDistance,
    requestGeoPermissions,
    isGeoPermissionsGranted,
  };
};
