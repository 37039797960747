import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface StylesProps {
  color?: string;
}

export const DividerStyled = styled.div<StylesProps>`
  width: 100%;
  border-top: 1px solid ${({ color }) => (color ? color : commonColors.grey300)};
`;
