import { ProfessionalCompanySubscriptionPaymentInitializationDto } from '@docbay/schemas';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RequestEnum } from 'application/store/reducers/request/model';

import { ProfessionalAPI } from 'integration/api/professional';

const subscriptionPayment = createAsyncThunk(
  RequestEnum.subscriptionPayment,
  async (
    params: ProfessionalCompanySubscriptionPaymentInitializationDto,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ProfessionalAPI.subscriptionPayment(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const fetchProfessionalSubscriptionById = createAsyncThunk(
  RequestEnum.fetchProfessionalSubscriptionById,
  async (subscriptionId: string, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalAPI.fetchProfessionalSubscriptionById(
        subscriptionId,
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchSubscriptionPaymentMethods = createAsyncThunk(
  RequestEnum.fetchSubscriptionPaymentMethods,
  async (stripeAccountId: string, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await ProfessionalAPI.getPaymentMethodsForStripeAccount(
        stripeAccountId,
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const ProfessionalActions = {
  subscriptionPayment,
  fetchProfessionalSubscriptionById,
  fetchSubscriptionPaymentMethods,
};
