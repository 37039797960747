import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { MapBoxAllLocations } from 'application/types/mapBox';

const sessionToken = uuid();
const accessToken = process.env.REACT_APP_MAP_BOX_TOKEN;

const axiosInstance = axios.create({
  baseURL: 'https://api.mapbox.com/search/searchbox/v1',
});

export const fetchAllLocations = (params: {
  address: string;
  country: string;
}) =>
  axiosInstance.get<MapBoxAllLocations>(
    `suggest?q=${params.address}&country=${params.country}&types=address,street,city,postcode&access_token=${accessToken}&session_token=${sessionToken}`,
  );

export const getLocations = (id: string) =>
  axiosInstance.get<any>(
    `retrieve/${id}?access_token=${accessToken}&session_token=${sessionToken}`,
  );

export const getLocationByCoordinate = (longitude: number, latitude: number) =>
  axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}&types=region`,
  );
