import { useCallback, useState } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';

export const useStripeForSubscriptions = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  const retrieveStripePaymentIntent = useCallback(
    async (clientSecret: string) => {
      if (!stripe) {
        return;
      }
      await stripe.retrievePaymentIntent(clientSecret);
    },
    [stripe],
  );

  const confirmStripePayment = async (
    clientSecret: string,
    paymentId: string,
  ) => {
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    if (paymentId) {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentId,
      });
      setIsLoading(false);
      return result;
    } else {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: 'if_required',
      });
      setIsLoading(false);
      return result;
    }
  };
  return {
    stripe,
    elements,
    retrieveStripePaymentIntent,
    confirmStripePayment,
    isLoading,
  };
};
