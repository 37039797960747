import { createAsyncThunk } from '@reduxjs/toolkit';
import { RequestEnum } from 'application/store/reducers/request/model';
import {
  HrDepartmentEmployeeCreationRequestDto,
  HrDepartmentEmployeeUpdateDto,
} from '@docbay/schemas';
import { EmployeesManagementAPI } from 'integration/api/employeesManagement';

interface Props {
  page?: number;
  limit?: number;
  languageIso?: string;
  departmentIds?: string[];
  search?: string;
}

export const createEmployee = createAsyncThunk(
  RequestEnum.createEmployee,
  async (
    params: HrDepartmentEmployeeCreationRequestDto,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await EmployeesManagementAPI.createEmployee(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchEmployees = createAsyncThunk(
  RequestEnum.fetchEmployees,
  async (params: Props, { rejectWithValue }) => {
    try {
      const { data } = await EmployeesManagementAPI.fetchEmployees(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const editEmployee = createAsyncThunk(
  RequestEnum.editEmployee,
  async (
    params: { data: HrDepartmentEmployeeUpdateDto; employeeId: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await EmployeesManagementAPI.editEmployee(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const deleteEmployee = createAsyncThunk(
  RequestEnum.deleteEmployee,
  async (employeeId: string, { rejectWithValue }) => {
    try {
      const { data } = await EmployeesManagementAPI.deleteEmployee(employeeId);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchEmployeeById = createAsyncThunk(
  RequestEnum.fetchEmployeeById,
  async (employeeId: string, { rejectWithValue }) => {
    try {
      const { data } = await EmployeesManagementAPI.fetchEmployeeById(
        employeeId,
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
