import { ProfessionalSocialMediaDto } from '@docbay/schemas';

export interface SocialMediaProps {
  socialMedia: ProfessionalSocialMediaDto[];
}

export enum SocialMediaType {
  Instagram = 'Instagram',
  Facebook = 'Facebook',
  Linkedin = 'Linkedin',
  Twitter = 'Twitter',
  Tiktok = 'Tiktok',
}
