import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'react-router-dom';

import { CloseIcon, SearchIcon } from 'application/assets';
import {
  CountrySwitcher,
  Dropdown,
  MapBoxAutocompleteInput,
} from 'application/components';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { MapBoxAllLocations, MapBoxSuggest } from 'application/types/mapBox';
import { setLocation } from 'application/store/reducers/MapBox/MapBoxSlice';
import { MapBoxActions } from 'application/store/reducers/MapBox/actions';
import { HrDepartmentSearchActions } from 'application/store/reducers/HrDepartmentSearch/actions';
import { getCountryCode } from 'application/helpers/sessionStorage/auth';
import { isResponseFulfilled } from 'application/helpers/responseHelper';
import { useLocalizeKey } from 'application/hooks/useLocalizeKey';
import { setFilters } from 'application/store/reducers/HrDepartmentSearch/hrDepartmentSearchSlice';

import {
  QuickSearchFromData,
  QuickTypes,
  useQuickSearchSchema,
} from 'features/find-doctor/hooks/useQuickSearchSchema';
import { getEmployeesPlanList } from 'features/find-doctor/helpers/getEmployeesPlanList';

import QuickSearch from '../QuickSearch';

import {
  Wrapper,
  SearchBarStyled,
  DividerVertical,
  SearchButton,
  BadgesList,
  BadgesStyled,
  ClearButton,
} from './styles';

const SearchGroup: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    location: { latitude, longitude },
  } = useAppSelector((state) => state.mapBox);
  const { specializations } = useAppSelector((state) => state.specializations);

  const [searchParams, setSearchParams] = useSearchParams({});
  const [showResultBySearchParams, setShowResultBySearchParams] =
    useState(false);

  const { localizeNameKey } = useLocalizeKey();
  const quickSearchSchema = useQuickSearchSchema();
  const employeesInPlanList = getEmployeesPlanList();

  const {
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<QuickSearchFromData>({
    resolver: yupResolver(quickSearchSchema),
  });
  const hasErrors = !!Object.keys(errors).length;
  const specialization = searchParams.get('searchEntity');
  const address = searchParams.get('address');
  const employeesPlan = searchParams.get('employees');

  const employeesInPlan = useMemo(() => {
    const minNumberOfEmployees = watch('employees.minNumberOfEmployees') || '';
    const maxNumberOfEmployees = watch('employees.maxNumberOfEmployees') || '';

    return [minNumberOfEmployees, maxNumberOfEmployees]
      .filter((item) => !!item)
      .join('-');
  }, [watch('employees')]);

  const filters = useMemo(() => {
    const employees =
      employeesInPlanList.find((item) => item.value === employeesInPlan)
        ?.label || '';
    return [
      { value: watch('searchEntity')?.name, type: 'searchEntity' as any },
      { value: watch('address')?.name, type: 'address' as any },
      { value: employees, type: 'employees' as any },
    ].filter((item) => !!item.value);
  }, [
    watch('address'),
    watch('searchEntity'),
    employeesInPlan,
    employeesInPlanList,
  ]);

  const setAddress = async () => {
    const country = getCountryCode();

    if (address) {
      await dispatch(
        MapBoxActions.fetchLocations({
          address: decodeURIComponent(address),
          country,
        }),
      ).then(async (data) => {
        const payload = data.payload as MapBoxAllLocations;
        payload?.suggestions?.length &&
          (await handleSetLocation(
            payload?.suggestions?.[0],
            payload?.response_id || '',
          ));
      });
    }
  };

  const setSpecialization = async () => {
    const currentSpecialization = specializations?.find(
      (item) =>
        item.name_PT === decodeURIComponent(specialization || '') ||
        item.name_EN === decodeURIComponent(specialization || '') ||
        item.name_FR === decodeURIComponent(specialization || ''),
    );
    if (!currentSpecialization) {
      return;
    }
    setValue('searchEntity', {
      id: currentSpecialization?.id,
      name: currentSpecialization?.[localizeNameKey],
    });
  };

  const setEmployeesInPlan = async () => {
    if (employeesPlan?.length) {
      const employeesInPlan = employeesPlan.split('-');
      const minNumberOfEmployees = Number(employeesInPlan[0]);
      const maxNumberOfEmployees =
        employeesInPlan.length > 1 ? Number(employeesInPlan[1]) : 0;
      setValue('employees', { minNumberOfEmployees, maxNumberOfEmployees });
    }
  };

  useEffect(() => {
    if (!specialization || specializations.length === 0) {
      return;
    }

    const setData = async () => {
      await setAddress();
      setSpecialization();
      setEmployeesInPlan();
      setShowResultBySearchParams(true);
    };

    setData();
  }, [specialization, address, employeesPlan, specializations]);

  useEffect(() => {
    const specializationName = specialization || watch('searchEntity')?.name;
    if (specializationName && specializations.length) {
      const currentSpecialization = specializations?.find(
        (item) =>
          item.name_PT === decodeURIComponent(specializationName) ||
          item.name_EN === decodeURIComponent(specializationName) ||
          item.name_FR === decodeURIComponent(specializationName),
      );
      if (!currentSpecialization) {
        return;
      }
      setValue('searchEntity', {
        id: currentSpecialization?.id,
        name: currentSpecialization?.[localizeNameKey],
      });
    }
  }, [localizeNameKey]);

  const handleChangeSearchEntity = (value: { id: string; name: string }) => {
    setValue('searchEntity', value, { shouldValidate: !!errors.searchEntity });
  };

  const handleSetLocation = async (
    location: MapBoxSuggest | null,
    id: string,
  ) => {
    const isRegion = location?.feature_type === 'region';
    const city = isRegion
      ? location?.name || ''
      : location?.context?.region?.name || '';
    const address = {
      name: [location?.name, location?.place_formatted]
        .filter((item) => !!item)
        .join(', '),
      id,
      city,
    };
    if (!location) {
      dispatch(setLocation({}));
    }
    location?.mapbox_id &&
      (await dispatch(MapBoxActions.getCoordinates(location.mapbox_id)));
    setValue('address', address, { shouldValidate: !!errors.address });
  };

  const handleChangeEmployeesInPlan = (value: string) => {
    const employeesInPlan = value.split('-');
    const minNumberOfEmployees = Number(employeesInPlan[0]);
    const maxNumberOfEmployees =
      employeesInPlan.length > 1 ? Number(employeesInPlan[1]) : 0;
    setValue('employees', { minNumberOfEmployees, maxNumberOfEmployees });
  };

  const onSubmit = async () => {
    const { searchEntity, address, employees } = watch();
    const countryCode = getCountryCode();

    const data = {
      specializationId: searchEntity.id,
      location: {
        countryCode,
        latitude,
        longitude,
        city: address?.city,
      },
      ...(!!employees?.minNumberOfEmployees
        ? { minNumberOfEmployees: employees.minNumberOfEmployees }
        : {}),
      ...(!!employees?.maxNumberOfEmployees
        ? { maxNumberOfEmployees: employees.maxNumberOfEmployees }
        : {}),
    };

    const response = await dispatch(
      HrDepartmentSearchActions.fetchHrDepartmentSearch({
        ...data,
        page: 1,
        limit: 10,
      }),
    );

    isResponseFulfilled(response, () => {
      dispatch(setFilters(data));
      setSearchParams({
        searchEntity: searchEntity.name,
        ...(address?.name?.length ? { address: address?.name } : {}),
        ...(employeesInPlan.length ? { employees: employeesInPlan } : {}),
      } as any);
    });
  };

  useEffect(() => {
    if (showResultBySearchParams) {
      onSubmit();
    }
  }, [showResultBySearchParams]);

  const onClear = (key: QuickTypes) => {
    const value =
      key === 'employees'
        ? { minNumberOfEmployees: 0, maxNumberOfEmployees: 0 }
        : key === 'address'
        ? { id: '', name: '', city: '' }
        : { id: '', name: '' };

    setValue(`${key}`, value);
    searchParams.delete('searchEntity');
    setSearchParams(searchParams);
  };

  const onClearAll = () => {
    reset();
    searchParams.delete('searchEntity');
    searchParams.delete('address');
    searchParams.delete('employees');
    setSearchParams(searchParams);
  };

  return (
    <Wrapper>
      <SearchBarStyled onSubmit={handleSubmit(onSubmit)}>
        <QuickSearch
          value={watch('searchEntity')}
          onChange={handleChangeSearchEntity}
          errorMessage={errors.searchEntity?.id?.message}
          onClear={() => onClear('searchEntity')}
        />
        <DividerVertical />
        <CountrySwitcher keyName="mainSearch" />
        <MapBoxAutocompleteInput
          id={'address'}
          label={t('global.city') || ''}
          value={watch('address') || {}}
          setLocation={handleSetLocation}
          errorMessage={errors.address?.message}
          hideErrorMessage
        />
        <DividerVertical />
        <Dropdown
          id="employees-in-plan"
          placeholder={t('find_doctor.search_group.employees_placeholder')}
          value={employeesInPlan}
          onChange={(option) =>
            !Array.isArray(option) &&
            handleChangeEmployeesInPlan(String(option.value))
          }
          options={employeesInPlanList}
        />
        <SearchButton type="submit" disabled={hasErrors}>
          <SearchIcon />
        </SearchButton>
      </SearchBarStyled>
      {!!filters.length && (
        <BadgesList>
          {filters.map((item, index) => (
            <BadgesStyled key={`badges-${index + 1}`}>
              <p>{item.value}</p>
              <button type="button" onClick={() => onClear(item.type)}>
                <CloseIcon />
              </button>
            </BadgesStyled>
          ))}
          <ClearButton type="button" onClick={onClearAll}>
            {t('global.clear_all')}
          </ClearButton>
        </BadgesList>
      )}
    </Wrapper>
  );
};

export default SearchGroup;
