import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { HelpBar } from 'application/components';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { requestSelector } from 'application/store/reducers/request/selectors';

import { Loader } from 'common';

import { Wrapper } from './styles';
import { fetchEmployeeById } from 'application/store/reducers/EmployeesManagement/actions';
import EmployeeProfileWrapper from './components/EmployeeProfileWrapper';

const EmployeeProfile: FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { fetchEmployeeById: fetchEmployeeByIdAction } =
    useAppSelector(requestSelector);

  const isLoading = fetchEmployeeByIdAction.loading;

  useEffect(() => {
    if (id) {
      dispatch(fetchEmployeeById(id));
    }
  }, [dispatch, id]);

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <HelpBar text={t('global.back')} />
      <EmployeeProfileWrapper />
    </Wrapper>
  );
};

export default EmployeeProfile;
