import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { EmptyStateIcon } from 'application/assets';
import { PathNames } from 'application/routes/constants';

import { Wrapper } from './styles';

const EmptyState: FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <EmptyStateIcon />
      <div>
        <p>{t('doctor_subscription.empty_state.title')}</p>
        <p>
          {t('doctor_subscription.empty_state.text_1')} {/* TODO */}
          <Link to={PathNames.findDoctor}>
            {t('doctor_subscription.choose_a_doctor')}
          </Link>{' '}
          {t('doctor_subscription.empty_state.text_2')}
        </p>
      </div>
    </Wrapper>
  );
};

export default EmptyState;
