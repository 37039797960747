import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MedicalInformationOutlined } from '@mui/icons-material';

import { BannerImage } from 'features/doctor-subscription/assets';

import { Wrapper, InfoStyled } from './styles';

const Banner: FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <img src={BannerImage} alt="banner" />
      <InfoStyled>
        <div>
          <MedicalInformationOutlined />
          <h2>{t('doctor_subscription.banner.title')}</h2>
        </div>
        <p>{t('doctor_subscription.banner.description')}</p>
      </InfoStyled>
    </Wrapper>
  );
};

export default Banner;
