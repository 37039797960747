import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  h1 {
    color: ${commonColors.grey900};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.2px;
  }
`;

export const SubscriptionStyled = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${commonColors.grey200};
  background: ${commonColors.white};
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);
`;

export const TopSection = styled.div`
  display: flex;
  padding: 12px 24px;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid ${commonColors.grey200};
  background: ${commonColors.green50};

  h1 {
    color: ${commonColors.grey900};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 12px;
`;

export const InfoList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;
  column-gap: 32px;
  row-gap: 8px;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;

    color: ${commonColors.grey700};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.04px;

    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;

      path {
        fill: ${commonColors.grey600};
      }
    }

    h2 {
      font-weight: 700;
    }
  }
`;

export const ActionSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 4px;

    color: ${commonColors.blue800};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.08px;

    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;

      path {
        fill: ${commonColors.blue800};
      }
    }
  }
`;
