import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface ItemStyledProps {
  isStrokeSvg?: boolean;
}

export const Wrapper = styled.article`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid ${commonColors.grey300};
  background: ${commonColors.white};
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);

  > button {
    height: 36px;
    width: fit-content;
    svg {
      path {
        fill: ${commonColors.blue800};
      }
    }
  }
`;

export const ItemsList = styled.section`
  display: flex;
  gap: 60px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 12px 8px;
  }
`;

export const ItemStyled = styled.div<ItemStyledProps>`
  display: flex;
  align-items: center;

  span {
    color: ${commonColors.grey500};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    margin: 0 6px 0 8px;
  }

  p {
    color: ${commonColors.grey700};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: 0.08px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    min-width: 20px;
    height: 20px;

    path {
      fill: ${({ isStrokeSvg }) => (!isStrokeSvg ? commonColors.grey600 : '')};
      stroke: ${({ isStrokeSvg }) => (isStrokeSvg ? commonColors.grey600 : '')};
    }
  }
`;
