import React, { FC, memo } from 'react';

import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
  TikTokIcon,
} from 'application/assets';

import { SocialMediaProps, SocialMediaType } from './models';
import { SocialWrapper } from './styles';

const getSocialMediaIcon = (name: string) => {
  switch (name) {
    case SocialMediaType.Instagram: {
      return <InstagramIcon />;
    }
    case SocialMediaType.Linkedin: {
      return <LinkedinIcon />;
    }
    case SocialMediaType.Twitter: {
      return <TwitterIcon />;
    }
    case SocialMediaType.Tiktok: {
      return <TikTokIcon />;
    }
    default: {
      return <FacebookIcon />;
    }
  }
};

const SocialMedia: FC<SocialMediaProps> = ({ socialMedia }) => {
  return (
    <SocialWrapper>
      {socialMedia.map((item) => (
        <span key={item.id}>
          {item.name && item.url && (
            <a href={item.url} target="_blank" rel="noreferrer">
              {getSocialMediaIcon(item.name)}
            </a>
          )}
        </span>
      ))}
    </SocialWrapper>
  );
};

export default memo(SocialMedia);
