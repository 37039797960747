import React, { FC } from 'react';

import { BenefitPlansList, Header } from './components';
import { Wrapper } from './styles';

const BenefitPlans: FC = () => {
  return (
    <Wrapper>
      <Header />
      <BenefitPlansList />
    </Wrapper>
  );
};

export default BenefitPlans;
