import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0 12px;
  gap: 32px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const PaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 670px;
  padding: 32px;
  border-radius: 12px;
  border: 1px solid ${commonColors.grey200};
  background: ${commonColors.white};
`;
