import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { getResponseError } from 'application/helpers/responseHelper';

import { ResponseDto } from 'integration/api/models';

import { errorCodesList } from './constants';

export const handleError = (action: PayloadAction<AxiosError<ResponseDto>>) => {
  const errorCode = getResponseError(action);
  const isErrorHandled = errorCodesList.some((code) => code === errorCode);
  return !isErrorHandled;
};
