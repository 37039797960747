import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export enum LocalizeNameKey {
  name_EN = 'name_EN',
  name_PT = 'name_PT',
  name_FR = 'name_FR',
}
export const useLocalizeKey = () => {
  const { i18n } = useTranslation();
  const localizeNameKey = useMemo(() => {
    return i18n.language.toLowerCase() === 'en'
      ? LocalizeNameKey.name_EN
      : i18n.language.toLowerCase() === 'fr'
      ? LocalizeNameKey.name_FR
      : LocalizeNameKey.name_PT;
  }, [i18n.language]);

  return {
    localizeNameKey,
  };
};
