import React, { useState, useEffect, useMemo } from 'react';
import { Wrapper } from './styles';
import { useTranslation } from 'react-i18next';
import Slider from '@mui/material/Slider';
import { useAppSelector } from 'application/hooks/redux';

const EmployeesRange = () => {
  const { t } = useTranslation();
  const [numberOfEmployees, setNumberOfEmployees] = useState<number>(0);
  const [isErrorState, setIsErrorState] = useState(false);

  const { chosenPackage, assignToPackage } = useAppSelector(
    (state) => state.employeesManagement,
  );

  useEffect(() => {
    if (assignToPackage && chosenPackage) {
      const newNumberOfEmployees =
        assignToPackage!.length > (chosenPackage?.maxNumberOfEmployees || 0)
          ? chosenPackage!.maxNumberOfEmployees || 0
          : assignToPackage!.length;

      const isError =
        assignToPackage!.length < (chosenPackage?.minNumberOfEmployees || 0) ||
        assignToPackage!.length > (chosenPackage?.maxNumberOfEmployees || 0);

      setNumberOfEmployees(newNumberOfEmployees);

      setIsErrorState(isError);
    }
  }, [assignToPackage, chosenPackage]);

  const rangeInfo = useMemo(() => {
    return `${assignToPackage!.length} / ${
      chosenPackage?.minNumberOfEmployees || 0
    } - ${chosenPackage?.maxNumberOfEmployees || 0}`;
  }, [assignToPackage, chosenPackage]);

  return (
    <Wrapper isError={isErrorState}>
      <Slider
        value={numberOfEmployees}
        min={0}
        max={chosenPackage?.maxNumberOfEmployees || 0}
        disableSwap={true}
      />
      <div>
        {t('assign_employees.selected_employees')}
        <span>{rangeInfo}</span>
      </div>
    </Wrapper>
  );
};

export default EmployeesRange;
