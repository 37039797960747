import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;

  h1 {
    color: ${commonColors.grey900};
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: capitalize;
  }

  p {
    color: ${commonColors.grey700};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;
