import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: 1px 1px 0 0 rgba(11, 37, 80, 0.17);
  border: 1px solid ${commonColors.grey200};
`;

export const TableStyled = styled.table`
  width: 100%;
  border-radius: 12px;
  border-collapse: separate;

  > thead {
    tr {
      th {
        vertical-align: middle;
        text-align: start;
        padding: 12px 24px;
        color: ${commonColors.grey600};
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.07px;
        background: ${commonColors.grey50};
        border-bottom: 1px solid ${commonColors.grey200};
      }
    }
  }
  > tbody {
    max-height: 200px;
    overflow-y: auto;
    > tr {
      &:last-child {
        > td {
          &:first-of-type {
            border-bottom-left-radius: 12px;
          }
          &:last-of-type {
            border-bottom-right-radius: 12px;
          }
        }
      }
    }
  }
`;

export const Title = styled.h1`
  color: ${commonColors.grey900};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.2px;

  span {
    color: ${commonColors.blue800};
  }
`;
