import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  height: fit-content;
  min-height: 110px;
  padding-right: 40px;
  border-radius: 8px;
  border: 1px solid ${commonColors.blue100};
  background: ${commonColors.blue50};

  > img {
    height: 110px;
    border-radius: 8px 0 0 8px;
  }
`;

export const InfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > div {
    display: flex;
    align-items: center;
    gap: 4px;

    svg {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;

      path {
        fill: ${commonColors.grey800};
      }
    }

    h2 {
      color: ${commonColors.grey900};
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.08px;
    }
  }

  > p {
    color: ${commonColors.grey900};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.07px;
  }
`;
