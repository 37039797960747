import React, { FC } from 'react';

import {
  FlagFranceIcon,
  FlagPortugalIcon,
  FlagUkraineIcon,
  FlagSpainIcon,
} from 'application/assets';

import { FlagProps } from './models';

const Flag: FC<FlagProps> = ({ code }) => {
  switch (code.toLowerCase()) {
    case 'fr':
      return <FlagFranceIcon />;
    case 'ua':
      return <FlagUkraineIcon />;
    case 'es':
      return <FlagSpainIcon />;
    default:
      return <FlagPortugalIcon />;
  }
};

export default Flag;
