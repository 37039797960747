import React, { FC } from 'react';

import { Wrapper } from './styles';
import { useTranslation } from 'react-i18next';
import EmployeeInfo from '../EmployeeInfo';

const EmployeeProfileWrapper: FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <h1>{t('dashboard.employee_profile')}</h1>
      <EmployeeInfo />
    </Wrapper>
  );
};

export default EmployeeProfileWrapper;
