import React, { FC } from 'react';

import { RadioButtonProps } from './models';
import { RadioButtonStyled } from './styles';

const RadioButton: FC<RadioButtonProps> = ({
  id,
  label,
  checked,
  onChange,
  subLabel,
}) => {
  return (
    <RadioButtonStyled>
      <input id={id} type="radio" checked={checked} onChange={onChange} />
      <label htmlFor={id}>
        {label}
        <span> {subLabel}</span>
      </label>
    </RadioButtonStyled>
  );
};

export default RadioButton;
