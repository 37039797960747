import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DividerStyled = styled.div`
  width: 100%;
  border-top: 1px solid ${commonColors.grey200};
`;

export const Title = styled.h1`
  color: ${commonColors.grey900};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.2px;
  padding: 20px;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  height: calc(96vh - 146px);
  overflow-y: auto;
`;

export const PackagesSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  > div:first-of-type {
    max-width: max-content;
  }
  > div:last-of-type {
    color: ${commonColors.green900};
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
  }

  div {
    section {
      h2 {
        margin-right: unset;
      }
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  width: 100%;
  padding: 20px;
  margin-top: auto;
  position: relative;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  button {
    width: 150px;
    padding: 12px 24px;
    white-space: nowrap;

    &:last-of-type {
      margin-left: 16px;
    }
  }
`;
