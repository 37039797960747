import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { CompanyPurchasedSubscriptionResponse } from '@docbay/schemas';

import { HelpBar, Pagination, SuccessModal } from 'application/components';
import { PathNames } from 'application/routes/constants';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { SubscriptionsActions } from 'application/store/reducers/Subscriptions/actions';
import { requestSelector } from 'application/store/reducers/request/selectors';
import { isResponseFulfilled } from 'application/helpers/responseHelper';
import { setSubscription } from 'application/store/reducers/Subscriptions/subscriptionsSlice';

import { Loader } from 'common';

import {
  Header,
  EmptyState,
  Banner,
  SubscriptionCard,
  EmployeesList,
  CancelSubscriptionModal,
} from './components';
import { Wrapper, SubscriptionsList } from './styles';

const DoctorSubscription: FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { subscriptions, totalPages, total, page } = useAppSelector(
    (state) => state.subscriptions,
  );
  const { getSubscriptions, getSubscriptionById, deleteSubscription } =
    useAppSelector(requestSelector);

  const [showEmployeesModal, setShowEmployeesModal] = useState(false);
  const [subscriptionToDelete, setSubscriptionToDelete] = useState<any | null>(
    null,
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);

  const isLoading = getSubscriptions.loading || getSubscriptionById.loading;

  const subscriptionPeriodEnd = dayjs(subscriptionToDelete?.currentPeriodEnd)
    .locale(i18n.language)
    .format('MMMM DD, YYYY');

  useEffect(() => {
    dispatch(SubscriptionsActions.getSubscriptions(1));
  }, []);

  const onSeeEmployees = async (id: string) => {
    const response = await dispatch(
      SubscriptionsActions.getSubscriptionById(id),
    );
    isResponseFulfilled(response, () => {
      setShowEmployeesModal(true);
    });
  };

  const handleCloseEmployeesModal = () => {
    setShowEmployeesModal(false);
    dispatch(setSubscription(null));
  };

  const onCancelSubscription = (
    subscription: CompanyPurchasedSubscriptionResponse,
  ) => {
    setShowDeleteModal(true);
    setSubscriptionToDelete(subscription);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSubscriptionToDelete(null);
  };

  const onSubmitCancelSubscription = async () => {
    if (subscriptionToDelete) {
      const response = await dispatch(
        SubscriptionsActions.deleteSubscription(subscriptionToDelete.id),
      );
      isResponseFulfilled(response, () => {
        handleCloseDeleteModal();
        setShowSuccessDeleteModal(true);

        const currentPage = subscriptions.length > 1 ? page : page - 1;
        const nextPage = currentPage > 1 ? currentPage : 1;
        dispatch(SubscriptionsActions.getSubscriptions(nextPage));
      });
    }
  };

  const handleChangePage = async (currentPage: number) => {
    await dispatch(SubscriptionsActions.getSubscriptions(currentPage));
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <HelpBar
        text={t('doctor_subscription.back_button_text') || ''}
        pathBack={PathNames.benefitPlans}
      />
      <Header />
      {subscriptions.length ? (
        <SubscriptionsList>
          {subscriptions.map((subscription) => (
            <SubscriptionCard
              key={subscription.id}
              subscription={subscription}
              onSeeEmployees={onSeeEmployees}
              onDelete={onCancelSubscription}
            />
          ))}
          <Pagination
            pages={totalPages}
            setCurrentPage={handleChangePage}
            currentPage={page}
            itemCount={total}
          />
        </SubscriptionsList>
      ) : (
        <>
          <EmptyState />
          <Banner />
        </>
      )}
      {showEmployeesModal && (
        <EmployeesList
          opened={showEmployeesModal}
          onClose={handleCloseEmployeesModal}
        />
      )}
      {showDeleteModal && (
        <CancelSubscriptionModal
          opened={showDeleteModal}
          onClose={handleCloseDeleteModal}
          onSubmit={onSubmitCancelSubscription}
          subscriptionEndDate={subscriptionPeriodEnd}
        />
      )}
      {showSuccessDeleteModal && (
        <SuccessModal
          opened={showSuccessDeleteModal}
          firstText={t('doctor_subscription.success_popup_title')}
          onClose={() => setShowSuccessDeleteModal(false)}
        />
      )}
    </Wrapper>
  );
};

export default DoctorSubscription;
