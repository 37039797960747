import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { RadioButton } from 'application/components';
import { VisaIcon } from 'application/assets';

import { StripePaymentMethodsProps } from './models';
import { CardStyled, PaymentMethodsStyled } from './styles';

const StripePaymentMethods: FC<StripePaymentMethodsProps> = ({
  paymentMethods,
  paymentId,
  handleChangePaymentMethod,
}) => {
  const { t } = useTranslation();

  return (
    <PaymentMethodsStyled>
      <h1>{t('subscription_payment.select_payment_method')}</h1>
      {paymentMethods.map((item) => (
        <CardStyled
          key={item.id}
          isActive={paymentId === item.id}
          onClick={() => handleChangePaymentMethod(item.id)}
        >
          <VisaIcon />
          <p>****** {item.card?.last4}</p>
          <RadioButton
            id={item.id}
            checked={paymentId === item.id}
            onChange={() => handleChangePaymentMethod(item.id)}
          />
        </CardStyled>
      ))}
    </PaymentMethodsStyled>
  );
};

export default StripePaymentMethods;
