import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { commonColors } from 'application/theme';

interface CarouselProps {
  showDots: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  gap: 5px;

  p {
    color: ${commonColors.grey800};
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: 0.035px;
  }
`;

export const VerticalCarousel = styled.div<CarouselProps>`
  width: 64px;
  height: 338px;

  .slick-track {
    transform: ${({ showDots }) => (showDots ? '' : 'none !important')};
  }

  .slick-cloned {
    display: ${({ showDots }) => (showDots ? 'block' : 'none')};
  }

  .slick-arrow {
    display: ${({ showDots }) => (showDots ? 'flex' : 'none')} !important;
  }

  .slick-dots.slick-thumb {
    display: ${({ showDots }) => (showDots ? 'block' : 'none')} !important;
  }

  .slick-prev:before,
  .slick-next:before {
    display: none;
  }

  .slick-prev {
    position: absolute;
    left: 25%;
    top: 17px;
    z-index: 12;
    cursor: pointer;
  }

  .slick-next {
    right: -25%;
    bottom: 20px;
    position: inherit;
    z-index: 12;
    cursor: pointer;
  }

  img {
    cursor: pointer;
  }
`;

export const ImageContainer = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`;

export const MainImageContainer = styled('div')`
  position: relative;
  cursor: pointer;
  width: 338px;
  height: 338px;

  > img {
    border-radius: 8px;
    opacity: 1;
    display: block;
    object-fit: cover;
    width: 100%;
    height: 338px;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }

  :hover div {
    opacity: 1;
  }
`;

export const AvatarContainer = styled('div')`
  cursor: pointer;
  width: 338px;
  height: 338px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: ${commonColors.blueDark100};

  > img {
    display: block;
    width: 50%;
    height: auto;
  }
`;

export const ImageOverlay = styled('div')`
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  width: 338px;
  height: 338px;
`;

export const IconButtonWrapper = styled(IconButton)`
  color: white;
`;

export const StyledImage = styled('img')`
  max-width: 100%;
  height: auto;
`;

export const MainPhotoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 4px;
`;

export const CustomArrow = styled('div')`
  display: flex !important;
  background: ${commonColors.grey100} !important;
  border-radius: 50% !important;
  height: 32px !important;
  width: 32px !important;
  align-items: center !important;
  justify-content: center !important;

  svg {
    transform: rotate(90deg);
    path {
      fill: ${commonColors.grey900};
    }
  }
`;
