import React, { FC } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { useAppSelector } from 'application/hooks/redux';
import { CommonErrorModal } from 'application/components';

import { PathNames } from './constants';
import { getCurrentRoutes, publicRouts } from './routes';
import ProtectedRoute from './ProtectedRoute';

export const AppRouter: FC = () => {
  const { isErrorModalVisible } = useAppSelector((state) => state.error);

  const authRouts = getCurrentRoutes();

  return (
    <BrowserRouter>
      <Routes>
        {authRouts.map(({ path, component }) => (
          <Route
            path={path}
            element={<ProtectedRoute>{component}</ProtectedRoute>}
            key={path}
          />
        ))}
        {publicRouts.map(({ path, component }) => (
          <Route path={path} element={component} key={path} />
        ))}
        <Route path="/*" element={<Navigate to={PathNames.login} />} />
      </Routes>
      {isErrorModalVisible && <CommonErrorModal />}
    </BrowserRouter>
  );
};
