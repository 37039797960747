export const getEmployeesPlanList = () => {
  return [
    { value: '1-10', label: '1-10' },
    { value: '11-30', label: '11-30' },
    { value: '31-50', label: '31-50' },
    { value: '51-100', label: '51-100' },
    { value: '101-200', label: '101-200' },
    { value: '201-500', label: '201-500' },
    { value: '501-1000', label: '501-1000' },
    { value: '1001', label: '1001+' },
  ];
};
