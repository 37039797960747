import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 20px;
`;

export const SearchBarStyled = styled.form`
  display: flex;
  align-items: center;
  gap: 20px;
  height: 60px;
  padding: 8px;
  border-radius: 100px;
  background: ${commonColors.white};
  border: 1px solid ${commonColors.grey300};

  .country-dropdown {
    width: 44px;
    > section {
      width: 44px;
      border: 0;
      box-shadow: none;
      background: none;
      padding: 10px 8px;

      > h2 {
        margin-right: 0;
        overflow: unset;

        span {
          margin-right: 0;
        }
      }
      svg {
        margin: 0;
      }
    }
    .country-option-list {
      min-width: fit-content;
    }
  }

  .mapbox-suggestions {
    min-width: 160px;
    input {
      border: 0;
      box-shadow: none;
      background: none;
    }

    .mapbox-suggestions-list {
      button {
        border: 0;
      }
    }
  }

  .dropdown {
    section {
      border: 0;
      box-shadow: none;
      background: none;
    }
  }
`;

export const DividerVertical = styled.div`
  width: 1px;
  min-width: 1px;
  height: 32px;
  background: ${commonColors.grey300};
`;

export const SearchButton = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 44px;
  min-height: 44px;
  width: 44px;
  height: 44px;
  border-radius: 100px;
  background: ${commonColors.blue300};

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;

    path {
      fill: ${commonColors.blue900};
    }
  }

  &:hover {
    background: ${commonColors.blue400};
  }

  &:disabled {
    background: ${commonColors.grey300};
    cursor: not-allowed;
  }
`;

export const BadgesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
`;

export const BadgesStyled = styled.div`
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: ${commonColors.grey200};

  p {
    color: ${commonColors.grey700};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0.07px;
  }

  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;

      path {
        fill: ${commonColors.grey600};
      }
    }
  }
`;

export const ClearButton = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  color: ${commonColors.blue800};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.08px;
`;
