import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 0;
`;

export const SubscriptionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .pagination-wrapper {
    background: unset;
    border-top: 0;
  }
`;
