import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { Input, ManageTableModal, SuccessModal } from 'application/components';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import {
  getResponseError,
  isResponseFulfilled,
} from 'application/helpers/responseHelper';
import { LocationsActions } from 'application/store/reducers/Locations/actions';
import { useLocationSchema } from 'features/locations/hooks/useLocationSchema';
import { requestSelector } from 'application/store/reducers/request/selectors';

import { ERROR_CODE } from 'common';

import { ResponseDto } from 'integration/api/models';

import { LocationFormData, LocationModalProps } from './models';

const LocationModal: FC<LocationModalProps> = ({
  opened,
  onClose,
  fetchLocations,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { location } = useAppSelector((state) => state.locations);
  const { addLocation, updateLocation } = useAppSelector(requestSelector);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const isLoading = addLocation.loading || updateLocation.loading;
  const isEdit = !!location?.id;
  const locationSchema = useLocationSchema();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<LocationFormData>({
    resolver: yupResolver(locationSchema),
    mode: 'onChange',
    defaultValues: { name: location?.name || '' },
  });

  const title = isEdit
    ? t('locations.edit_location')
    : t('locations.add_location');

  const successTitle = useMemo(() => {
    return isEdit
      ? t('locations.location')
      : t('locations.success_modal.add_text');
  }, [isEdit, t]);

  const isStateChanged = location?.name !== watch('name');

  const errorHandler = (action: PayloadAction<AxiosError<ResponseDto>>) => {
    const errorCode = getResponseError(action);
    const isLocationNameError =
      errorCode === ERROR_CODE.HR_LOCATION_NAME_ALREADY_EXISTS;

    if (isLocationNameError) {
      setError('name', { message: t('errors.location_name_already_exist') });
    }
  };

  const onSubmit = async (data: LocationFormData) => {
    const { name } = data;
    if (isEdit) {
      const response = await dispatch(
        LocationsActions.updateLocation({
          locationId: location.id,
          name,
        }),
      );

      isResponseFulfilled(response, () => {
        setShowSuccessModal(true);
      });
    } else {
      const response = await dispatch(LocationsActions.addLocation({ name }));

      isResponseFulfilled(
        response,
        () => {
          setShowSuccessModal(true);
        },
        () => {
          errorHandler(response as PayloadAction<AxiosError<ResponseDto>>);
        },
      );
    }
  };

  const onCloseSuccessModal = () => {
    fetchLocations(1);
    onClose();
    setShowSuccessModal(false);
  };

  return (
    <>
      {showSuccessModal ? (
        <SuccessModal
          opened={showSuccessModal}
          onClose={onCloseSuccessModal}
          firstText={successTitle}
          boldText={isEdit ? watch('name') : ''}
          actionText={isEdit ? t('locations.success_modal.edit_text') : ''}
        />
      ) : (
        <ManageTableModal
          opened={opened}
          title={title}
          onClose={onClose}
          disabledSubmit={!!Object.keys(errors).length || !isStateChanged}
          onSubmit={handleSubmit(onSubmit)}
          isLoading={isLoading}
        >
          <Input
            id={'name'}
            placeholder={t('locations.location_placeholder')}
            label={t('locations.table.location_name')}
            type={'text'}
            register={register}
            errorMessage={errors?.name?.message}
          />
        </ManageTableModal>
      )}
    </>
  );
};

export default LocationModal;
