import React, { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useOnClickOutside from 'application/hooks/useOnClickOutside';
import { ExpandMoreIcon, HomeIcon } from 'application/assets';

import Checkbox from '../Checkbox';

import { DropdownClinicsProps, Option } from './models';
import {
  ClinicImage,
  DropdownStyled,
  OptionCheckBox,
  OptionsList,
  Wrapper,
} from './styles';

const DropdownClinics: FC<DropdownClinicsProps> = ({
  value,
  label,
  placeholder,
  onChange,
  disabled,
  isMulti,
  isError,
  errorMessage,
  options,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);

  const [isActive, setIsActive] = useState(false);

  useOnClickOutside(ref, () => setIsActive(false));

  const optionList = useMemo(() => {
    const filteredClinics = options.map((clinic) => ({
      label: clinic.name!,
      value: clinic.id!,
      image: clinic.mainPhoto?.thumbnailUrl,
      address: clinic.address,
    }));
    return filteredClinics;
  }, [options]);

  const handleChange = (selectedItem: string) => {
    if (!isMulti) {
      onChange(selectedItem);
      setIsActive(false);
    }

    if (Array.isArray(value) && isMulti) {
      const valueAlreadyAdded = value.some((item) => item === selectedItem);
      if (valueAlreadyAdded) {
        const filteredValue = value.filter((item) => {
          return item !== selectedItem;
        });

        onChange(filteredValue);
      } else {
        onChange([...value, selectedItem]);
      }
    }
    return;
  };

  const IsValueChecked = (item: Option) => {
    if (!isMulti) return String(value) === String(item.value);
    //checking if checkbox checked
    const isValueArray = Array.isArray(value);
    return isValueArray && value.some((i) => i === item.value);
  };

  const getValue = () => {
    if (isMulti) return `${value.length} ${t('global.selected')}`;
    const currentOption = optionList.find(
      (item) => String(item.value) === String(value),
    );
    return currentOption?.label;
  };

  return (
    <Wrapper isError={isError}>
      {label && <label>{label}</label>}
      <DropdownStyled
        isError={isError}
        isActive={isActive}
        disabled={disabled}
        ref={ref}
      >
        <section onMouseDown={() => !disabled && setIsActive((prev) => !prev)}>
          {value?.length ? (
            <h2>{getValue()}</h2>
          ) : (
            <span>{placeholder || t('all_clinics')}</span>
          )}
          <ExpandMoreIcon />
        </section>
        {isActive && (
          <OptionsList className="clinics-list">
            {isActive &&
              optionList.map((item: Option) => (
                <OptionCheckBox
                  htmlFor={String(item.value)}
                  key={item.value}
                  selected={IsValueChecked(item!)}
                  isMulti={isMulti}
                  isHidden={item.isHidden}
                >
                  <div>
                    <ClinicImage>
                      {item.image ? (
                        <img src={item.image} alt={item.label} />
                      ) : (
                        <HomeIcon />
                      )}
                    </ClinicImage>
                    <p>{item.label}</p>
                  </div>
                  <Checkbox
                    id={String(item.value)}
                    checked={IsValueChecked(item)}
                    onChange={() => handleChange(String(item.value))}
                  />
                </OptionCheckBox>
              ))}
          </OptionsList>
        )}
      </DropdownStyled>
      {errorMessage && <span>{errorMessage}</span>}
    </Wrapper>
  );
};

export default DropdownClinics;
