import { useTranslation } from 'react-i18next';

import { useGeolocation } from './useGeolocation';

interface Props {
  latitude?: string;
  longitude?: string;
  distanceInMeters?: number;
}

export const useClinicDistance = ({
  latitude,
  longitude,
  distanceInMeters,
}: Props) => {
  const { getDistance } = useGeolocation();

  const { t } = useTranslation();

  const amount = getDistance({ latitude, longitude });
  if (distanceInMeters) {
    return `${Math.round(distanceInMeters / 1000)} ${t('global.km')} ${t(
      'global.away',
    )}`;
  }
  if (amount !== null) {
    return `${Math.round(amount)} ${t('global.km')} ${t('global.away')}`;
  }
  return '';
};
