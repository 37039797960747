import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 32px;
  border-radius: 12px;
  border: 1px solid ${commonColors.grey200};
  background: ${commonColors.white};
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);

  > div {
    display: flex;
    flex-direction: column;

    p {
      color: ${commonColors.grey900};
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.08px;

      a {
        text-decoration: none;
        color: ${commonColors.blue800};
        font-weight: 700;
      }
    }
  }
`;
