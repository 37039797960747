import React, { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ClinicProfessionalResponseDto } from '@docbay/schemas';
import { PersonOutline } from '@mui/icons-material';

import { SecondaryButton } from 'application/components';
import { HomeWorkIcon, LocationOnIcon } from 'application/assets';
import { formatAddress } from 'application/helpers/address';
import { useLocalizeKey } from 'application/hooks/useLocalizeKey';
import { PathNames } from 'application/routes/constants';

import { DoctorCardProps } from './models';
import {
  Wrapper,
  Info,
  DoctorInfo,
  TopSection,
  ClinicInfo,
  TabsStyled,
  TabStyled,
  ClinicDescription,
  SubscriptionsSection,
  SubscriptionsList,
  AvatarStyled,
} from './styles';

const DoctorCard: FC<DoctorCardProps> = ({ professional }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { localizeNameKey } = useLocalizeKey();

  const [currentClinic, setCurrentClinic] =
    useState<ClinicProfessionalResponseDto | null>(null);

  const selectedClinic = useMemo(() => {
    const clinics = professional.clinicsRelations?.length
      ? professional.clinicsRelations
      : [];
    return currentClinic || clinics[0];
  }, [currentClinic, professional.clinicsRelations]);

  const professionalSpecializations = useMemo(() => {
    const specializations =
      professional.specializations?.map((item) => item[localizeNameKey]) || [];
    return specializations.join(', ');
  }, [professional.specializations, localizeNameKey]);

  const professionalFullName = useMemo(() => {
    const prefix =
      professional.gender === 'Male'
        ? t('global.drMale')
        : t('global.drFemale');

    return `${prefix} ${professional.firstName + ' ' + professional.lastName}`;
  }, [professional, t]);

  const onSeeProfile = () => {
    navigate(`${PathNames.doctorProfile}/${professional.id}`);
  };

  return (
    <Wrapper>
      <Info>
        <DoctorInfo>
          <TopSection>
            <AvatarStyled>
              {professional.mainPhoto ? (
                <img
                  src={professional.mainPhoto.thumbnailUrl}
                  alt={'professional-photo'}
                  width={84}
                  height={84}
                />
              ) : (
                <PersonOutline />
              )}
            </AvatarStyled>
            <div>
              <h2>{professionalFullName}</h2>
              {professional.specializations?.length && (
                <p>{professionalSpecializations}</p>
              )}
            </div>
          </TopSection>
          <ClinicInfo>
            <TabsStyled>
              {professional.clinicsRelations?.map((clinic, index) => (
                <TabStyled
                  type="button"
                  active={clinic.id === selectedClinic?.id || index === 0}
                  onClick={() => {
                    setCurrentClinic(clinic);
                  }}
                >
                  <p>
                    {t('global.clinic')} {index + 1}
                  </p>
                </TabStyled>
              ))}
            </TabsStyled>
            <ClinicDescription>
              <div>
                <HomeWorkIcon />
                <p>{selectedClinic?.clinic?.name}</p>
              </div>
              <div>
                <LocationOnIcon />
                <p>
                  {formatAddress(
                    t('global.room'),
                    t('global.floor'),
                    selectedClinic.clinic?.address,
                  )}
                </p>
              </div>
            </ClinicDescription>
          </ClinicInfo>
        </DoctorInfo>
        <SecondaryButton
          styleType={'color'}
          type="button"
          onClick={onSeeProfile}
        >
          {t('find_doctor.doctor_card.see_profile')}
        </SecondaryButton>
      </Info>
      {professional.clinicsRelations?.map((clinic) => (
        <SubscriptionsSection key={clinic.id}>
          <h2>{t('find_doctor.doctor_card.available_subscriptions')}</h2>
          <SubscriptionsList>
            <ul>
              {clinic.subscriptions?.map((subscription) => (
                <li key={subscription.id}>
                  {subscription.title}:
                  <span>
                    {subscription.minNumberOfEmployees}-
                    {subscription.maxNumberOfEmployees} {t('employees')},
                    {` €${subscription.price} ${t(
                      'assign_employees.per_employee',
                    )}`}
                  </span>
                </li>
              ))}
            </ul>
          </SubscriptionsList>
        </SubscriptionsSection>
      ))}
    </Wrapper>
  );
};

export default DoctorCard;
