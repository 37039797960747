import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'application/hooks/useDebounce';
import NoDataElement from '../NoDataElement';
import FilterSection from '../FilterSection';
import { TableStyled, Wrapper } from './styles';
import { PatientsTableProps } from './models';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { fetchEmployees } from 'application/store/reducers/EmployeesManagement/actions';
import EmployeeTableItem from '../EmployeeTableItem';
import { Pagination } from 'application/components';
import Checkbox from 'application/components/Checkbox';
import { setAssignToPackage } from 'application/store/reducers/EmployeesManagement/EmployeesManagementSlice';
import { AssignToPackageProps } from 'application/store/reducers/EmployeesManagement/models';

const defaultLimit = 5;

const EmployeesTable: FC<PatientsTableProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchItem, setSearchItem] = useState('');
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const searchByName = useDebounce(searchItem, 500);

  const { employees, totalPages, assignToPackage } = useAppSelector(
    (state) => state.employeesManagement,
  );

  const isCheckedAll = useMemo(() => {
    const isExist = (currentValue: any) => {
      return assignToPackage?.some((item) => item.id === currentValue.id);
    };

    return employees.every(isExist);
  }, [employees, assignToPackage]);

  const getEmployees = (page?: number) => {
    const newPage = page ? page : currentPage;

    const params = {
      page: newPage,
      limit: defaultLimit,
      ...(searchByName.length ? { search: searchByName } : {}),
      ...(selectedDepartments.length
        ? { departmentIds: selectedDepartments }
        : {}),
    };

    setCurrentPage(newPage);
    dispatch(fetchEmployees(params));
  };

  useEffect(() => {
    getEmployees();
  }, [currentPage]);

  useEffect(() => {
    getEmployees(1);
  }, [selectedDepartments, searchByName]);

  const handleChangePage = (page: number) => {
    getEmployees(page);
  };

  const handleChangeCheckAll = () => {
    if (!isCheckedAll) {
      const employeeArr: AssignToPackageProps[] = [];

      employees.forEach((employee) => {
        const isExist = assignToPackage.find((item) => item.id === employee.id);

        !isExist &&
          employeeArr.push({
            id: employee.id,
            name: `${employee.firstName} ${employee.lastName}`,
          });
      });

      dispatch(setAssignToPackage([...assignToPackage, ...employeeArr]));
    } else {
      const employeeArr = assignToPackage.filter(
        (item) => !employees.some((employee) => employee.id === item.id),
      );

      dispatch(setAssignToPackage(employeeArr));
    }
  };

  return (
    <>
      <Wrapper>
        <FilterSection
          searchItem={searchItem}
          setSearchItem={setSearchItem}
          selectedDepartments={selectedDepartments}
          setDepartments={setSelectedDepartments}
        />
        <TableStyled>
          <thead>
            <tr>
              <th>
                <Checkbox
                  id={'all'}
                  onChange={handleChangeCheckAll}
                  checked={isCheckedAll}
                />
              </th>
              <th>
                <span>{t('staff.employees_table.name')}</span>
              </th>
              <th>
                <span>{t('staff.employees_table.phone')}</span>
              </th>
              <th>
                <span>{t('staff.employees_table.department')}</span>
              </th>
              <th>
                <span>{t('staff.employees_table.position')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {employees.length ? (
              employees.map((employee) => {
                return <EmployeeTableItem data={employee} key={employee.id} />;
              })
            ) : (
              <NoDataElement
                isWithSearch={
                  !!selectedDepartments.length || !!searchByName.length
                }
              />
            )}
          </tbody>
        </TableStyled>
        {employees.length ? (
          <Pagination
            pages={totalPages}
            setCurrentPage={handleChangePage}
            currentPage={currentPage}
            itemCount={employees.length}
          />
        ) : (
          ''
        )}
      </Wrapper>
    </>
  );
};

export default EmployeesTable;
