import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid ${commonColors.grey300};
  background: ${commonColors.white};

  h1 {
    color: ${commonColors.grey900};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.05px;
  }
`;

export const InsurancesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InsuranceStyled = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;

  svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;

    path {
      fill: ${commonColors.grey600};
    }
  }

  p {
    color: ${commonColors.grey800};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.04px;
  }
`;

export const ShowMoreButton = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 4px;

  color: ${commonColors.blue800};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.08px;

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;

    path {
      fill: ${commonColors.blue800};
    }
  }
`;
