import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface TabItemProps {
  active: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid ${commonColors.grey300};
  background: ${commonColors.white};
`;

export const TabsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  h1 {
    color: ${commonColors.grey900};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.05px;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;

export const TabItem = styled.button<TabItemProps>`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  width: fit-content;
  height: 44px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  border: 1px solid ${commonColors.green600};
  background: ${({ active }) =>
    active ? commonColors.green600 : commonColors.green50};

  color: ${({ active }) =>
    active ? commonColors.white : commonColors.grey700};
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ active }) => (active ? 700 : 500)};
  line-height: 24px;
  letter-spacing: 0.5px;
`;

export const ClinicInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  > div {
    display: flex;
    align-items: flex-start;
    gap: 4px;

    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;

      path {
        fill: ${commonColors.grey600};
      }
    }

    p {
      color: ${commonColors.grey800};
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.04px;
    }
  }
`;
