import { loadStripe, StripeConstructorOptions } from '@stripe/stripe-js';

const STRIPE_KEY = process.env.REACT_APP_STRIPE_PAYMENT_KEY || '';

export const useStripeLoader = () => {
  const getStripePromise = (stripeAccount?: string) => {
    const options: StripeConstructorOptions = {
      stripeAccount,
    };
    return loadStripe(STRIPE_KEY, options);
  };

  return {
    getStripePromise,
  };
};
