import { createSlice } from '@reduxjs/toolkit';

import { InitialState } from './models';
import { ProfessionalActions } from './actions';

const initialState: InitialState = {
  stripeAccountId: '',
  subscriptionId: '',
  clientSecret: '',
  subscription: null,
  paymentMethods: [],
};

const professionalSlice = createSlice({
  name: 'professionalSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    //subscription payment
    builder.addCase(
      ProfessionalActions.subscriptionPayment.fulfilled,
      (state, action) => {
        state.stripeAccountId = action.payload.stripeAccountId;
        state.subscriptionId = action.payload.subscriptionId;
        state.clientSecret = action.payload.clientSecret;
      },
    );
    //fetch professional subscription by id
    builder.addCase(
      ProfessionalActions.fetchProfessionalSubscriptionById.fulfilled,
      (state, action) => {
        state.subscription = action.payload;
      },
    );
    //fetch subscription payment methods
    builder.addCase(
      ProfessionalActions.fetchSubscriptionPaymentMethods.fulfilled,
      (state, action) => {
        state.paymentMethods = action.payload;
      },
    );
  },
});

export const {} = professionalSlice.actions;

export default professionalSlice.reducer;
