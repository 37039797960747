export enum PathNames {
  home = '/',
  error = '/error',
  dashboard = '/dashboard',
  dashboardEmployeeProfile = '/dashboard/employee-profile',
  hrManagement = '/hr-management',
  employeesManagement = '/employees-management',
  settings = '/settings',
  support = '/support',
  companyRegistration = '/company-registration',
  login = '/login',
  forgotPassword = '/forgot-password',
  confirmPassword = '/confirm-password',
  createNewPassword = '/create-new-password',
  //settings
  companyBilling = '/settings/company-billing',
  departments = '/settings/departments',
  locations = '/settings/locations',
  benefitPlans = '/settings/benefit-plans',
  doctorSubscription = '/settings/benefit-plans/doctor-subscription',
  findDoctor = '/settings/benefit-plans/doctor-subscription/find-doctor',
  doctorProfile = '/settings/benefit-plans/doctor-subscription/find-doctor/doctor-profile',
  subscriptionPayment = '/subscription-payment',
}
