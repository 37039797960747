import { createSlice } from '@reduxjs/toolkit';
import { CountryActions } from './actions';
import { CountriesState } from './models';
import { getCountryCode } from 'application/helpers/sessionStorage/auth';

const initialState: CountriesState = {
  countries: [],
  currentUserCountry: getCountryCode(),
  error: '',
};

const countriesSlice = createSlice({
  name: 'patientSlice',
  initialState,
  reducers: {
    setCurrentUserCountry: (state, action) => {
      state.currentUserCountry = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get supported countries
    builder.addCase(
      CountryActions.getSupportedCountries.rejected,
      (state, action) => {
        state.error = action.payload as string;
      },
    );
    builder.addCase(
      CountryActions.getSupportedCountries.fulfilled,
      (state, action) => {
        state.countries = action.payload.entities;
      },
    );
    // set country for user
    builder.addCase(CountryActions.setUserCountry.rejected, (state, action) => {
      state.error = action.payload as string;
    });
    builder.addCase(
      CountryActions.setUserCountry.fulfilled,
      (state, action) => {
        state.currentUserCountry = action.meta.arg.countryIso;
      },
    );
  },
});

export const { setCurrentUserCountry } = countriesSlice.actions;

export default countriesSlice.reducer;
