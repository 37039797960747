import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { commonColors } from 'application/theme';
import {
  ClinicIcon,
  GroupsIcon,
  IdCardIcon,
  MedicalServicesIcon,
  TimeIcon,
  VideoCameraIcon,
  WalletIcon,
} from 'application/assets';
import { useAppSelector } from 'application/hooks/redux';
import { Gender } from 'application/constants/gender';
import { useLocalizeKey } from 'application/hooks/useLocalizeKey';

import {
  Wrapper,
  Divider,
  InfoSection,
  ContentItem,
  IconStyled,
} from './styles';

const SubscriptionInfo: FC = () => {
  const { t } = useTranslation();
  const { subscription } = useAppSelector((state) => state.professional);
  const { localizeNameKey } = useLocalizeKey();

  const professional = subscription?.professional;

  const professionalFullName = useMemo(() => {
    if (!professional) return '';
    const isMale = professional.gender === Gender.Male;
    const genderTitle = `${t(isMale ? 'global.drMale' : 'global.drFemale')}`;

    return `${genderTitle} ${professional.firstName} ${professional.lastName}`;
  }, [professional, t]);

  const professionalSpecializations = useMemo(() => {
    if (!professional?.professionalSpecializations?.length) return '';

    return professional.professionalSpecializations
      .map((item) => item[localizeNameKey])
      .join(', ');
  }, [professional, localizeNameKey]);

  const employeesRange = useMemo(() => {
    const minNumberOfEmployees = subscription?.minNumberOfEmployees || '';
    const maxNumberOfEmployees = subscription?.maxNumberOfEmployees || '';

    return [minNumberOfEmployees, maxNumberOfEmployees]
      .filter((item) => !!item)
      .join(' - ');
  }, [subscription]);

  const durationInWeeks = `${subscription?.durationInWeeks} ${t(
    Number(subscription?.durationInWeeks || 0) > 1
      ? 'global.weeks'
      : 'global.week',
  )}`;

  const renewsDate = dayjs()
    .set('date', dayjs().get('date') + 7)
    .format('MMM D, YYYY');

  return (
    <Wrapper>
      <h1>{t('subscription_payment.subscription_information')}</h1>
      <Divider color={commonColors.grey300} />
      <InfoSection>
        <ContentItem>
          <IconStyled>
            <IdCardIcon className="stroke" />
          </IconStyled>
          <div>
            <h2>{t('global.doctor')}</h2>
            <h1>{professionalFullName}</h1>
            <p>{professionalSpecializations}</p>
          </div>
        </ContentItem>
        <Divider />
        <ContentItem>
          <IconStyled>
            <MedicalServicesIcon className="small" />
          </IconStyled>
          <div>
            <h2>{t('subscription_payment.name_of_subscription')}</h2>
            <h1>{subscription?.title}</h1>
          </div>
        </ContentItem>
        <Divider />
        <ContentItem>
          <IconStyled>
            <ClinicIcon />
          </IconStyled>
          <div>
            <h2>{t('global.clinic')}</h2>
            <h1>{subscription?.clinic.name}</h1>
          </div>
        </ContentItem>
        <Divider />
        <ContentItem>
          <IconStyled>
            <GroupsIcon />
          </IconStyled>
          <div>
            <h2>{t('global.employee_range')}</h2>
            <h1>
              {employeesRange} {t('global.employees')}
            </h1>
          </div>
        </ContentItem>
        <Divider />
        <ContentItem>
          <IconStyled>
            <WalletIcon />
          </IconStyled>
          <div>
            <h2>{t('global.price_per_employee')}</h2>
            <h1>€{subscription?.price}</h1>
          </div>
        </ContentItem>
        <Divider />
        <ContentItem>
          <IconStyled>
            <TimeIcon />
          </IconStyled>
          <div>
            <h2>{t('global.duration')}</h2>
            <h1>{durationInWeeks}</h1>
            <p>
              {t('global.renews')} {renewsDate}
            </p>
          </div>
        </ContentItem>
        <Divider />
        <ContentItem>
          <IconStyled>
            <VideoCameraIcon />
          </IconStyled>
          <div>
            <h2>{t('subscription_payment.teleconsultation_per_employee')}</h2>
            <h1>
              {subscription?.teleconsultationMinutes} {t('global.min')}
            </h1>
          </div>
        </ContentItem>
      </InfoSection>
    </Wrapper>
  );
};

export default SubscriptionInfo;
