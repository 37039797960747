import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PickIcon } from 'application/assets';
import { StyledTr } from './styles';
import { NoDataElementProps } from './models';

const NoDataElement: FC<NoDataElementProps> = ({ isWithSearch }) => {
  const { t } = useTranslation();

  return (
    <StyledTr>
      <td colSpan={5}>
        <div>
          <PickIcon />
          {isWithSearch ? (
            <p>{t('staff.no_data.no_user')}</p>
          ) : (
            <p>{t('staff.no_data.title')}</p>
          )}
        </div>
      </td>
    </StyledTr>
  );
};
export default NoDataElement;
