import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'application/hooks/redux';
import { useLocalizeKey } from 'application/hooks/useLocalizeKey';

import SocialMedia from '../SocialMedia';

import {
  Wrapper,
  InfoSection,
  TopSection,
  NameStyled,
  SpecializationStyled,
  BottomSection,
  Description,
  AboutStyled,
  SubTitle,
} from './styles';
import DoctorGallery from '../DoctorGallery';

const DoctorInfo: FC = () => {
  const { t } = useTranslation();
  const { profile } = useAppSelector((state) => state.hrDepartmentSearch);

  const { localizeNameKey } = useLocalizeKey();

  const genderLabel =
    profile?.gender === 'Male'
      ? `${t('global.drMale')}`
      : `${t('global.drFemale')}`;
  const fullName = [profile?.firstName, profile?.lastName]
    .filter((item) => !!item)
    .join(' ');

  const specializations = useMemo(() => {
    const doctorSpecializations =
      profile?.specializations?.map(
        (specialization) => specialization[localizeNameKey],
      ) || [];
    return doctorSpecializations.join(', ');
  }, [profile?.specializations, localizeNameKey]);

  const professionalSpokenLanguages = useMemo(() => {
    const doctorLanguages =
      profile?.professionalSpokenLanguages?.map(
        (item) => item[localizeNameKey],
      ) || [];
    return doctorLanguages.join(', ');
  }, [profile?.professionalSpokenLanguages, localizeNameKey]);

  const socialMedia = profile?.socialMedia || [];

  return (
    <Wrapper>
      {profile && (
        <>
          <DoctorGallery />
          <InfoSection>
            <TopSection>
              <NameStyled>
                {genderLabel} {fullName}
              </NameStyled>
              <SpecializationStyled>{specializations}</SpecializationStyled>
            </TopSection>

            <BottomSection>
              {profile.professionalSpokenLanguages?.length ? (
                <Description>
                  <SubTitle>{t('global.spoken_languages')}:</SubTitle>
                  {professionalSpokenLanguages}
                </Description>
              ) : null}

              {profile.description ? (
                <AboutStyled>
                  <SubTitle>{t('global.about')}:</SubTitle>
                  <textarea value={profile.description} readOnly={true} />
                </AboutStyled>
              ) : null}
              {socialMedia.length ? (
                <SocialMedia socialMedia={socialMedia} />
              ) : null}
            </BottomSection>
          </InfoSection>
        </>
      )}
    </Wrapper>
  );
};

export default DoctorInfo;
