import React from 'react';
import { PhotoResponseDto } from '@docbay/schemas';
import Slider, { Settings } from 'react-slick';
import { Modal } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import { useAppSelector } from 'application/hooks/redux';
import { CloseIcon } from 'application/assets';

import { ArrowProps, GalleryPreviewModalProps } from './models';
import {
  Carousel,
  ImageContainer,
  PreviewImageContainer,
  StyledModal,
  CustomArrow,
} from './styles';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const SampleNextArrow: React.FC<ArrowProps> = ({ className, onClick }) => {
  return (
    <CustomArrow className={className} onClick={onClick}>
      <ChevronRight width={20} height={20} />
    </CustomArrow>
  );
};

const SamplePrevArrow: React.FC<ArrowProps> = ({ className, onClick }) => {
  return (
    <CustomArrow className={className} onClick={onClick}>
      <ChevronLeft width={20} height={20} />
    </CustomArrow>
  );
};

const GalleryPreviewModal: React.FC<GalleryPreviewModalProps> = ({
  isOpenModal,
  onCloseModal,
}) => {
  const { profile } = useAppSelector((state) => state.hrDepartmentSearch);

  const allPhotos: PhotoResponseDto[] = [...(profile?.photos || [])].filter(
    (photo): photo is PhotoResponseDto => !!photo,
  );

  const sliderSettings: Settings = {
    customPaging: (pageIndex: number) => {
      return (
        <a>
          {allPhotos?.length && (
            <PreviewImageContainer key={pageIndex}>
              <img
                src={allPhotos[pageIndex].thumbnailUrl}
                alt={`preview ${pageIndex}`}
                width={64}
                height={64}
              />
            </PreviewImageContainer>
          )}
        </a>
      );
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Modal open={isOpenModal} onClose={onCloseModal}>
      <StyledModal>
        <div onClick={onCloseModal}>
          <CloseIcon />
        </div>
        {allPhotos && allPhotos.length ? (
          <Carousel showDots={allPhotos.length > 1}>
            <Slider {...sliderSettings}>
              {allPhotos.map((photo, index) => (
                <ImageContainer key={photo.key}>
                  <img
                    src={photo.photoUrl}
                    alt={`img ${index}`}
                    width={64}
                    height={64}
                  />
                </ImageContainer>
              ))}
            </Slider>
          </Carousel>
        ) : null}
      </StyledModal>
    </Modal>
  );
};

export default GalleryPreviewModal;
