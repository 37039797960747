import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from 'application/components';
import { commonColors } from 'application/theme';
import { useAppSelector } from 'application/hooks/redux';

import { Wrapper, TotalSection, Amount, Title } from './styles';

const SelectedEmployees: FC = () => {
  const { t } = useTranslation();
  const { subscription } = useAppSelector((state) => state.professional);
  const { assignToPackage } = useAppSelector(
    (state) => state.employeesManagement,
  );

  const selectedEmployees = assignToPackage.length;

  const totalPrice = (subscription?.price || 0) * selectedEmployees;

  return (
    <Wrapper>
      <h1>{t('global.selected_employees')}</h1>
      <TotalSection>
        <Amount>
          <p>{t('global.price_per_employee')}:</p>
          <p>€{subscription?.price}</p>
        </Amount>
        <Amount>
          <p>{t('global.selected_employees')}:</p>
          <p>{selectedEmployees}</p>
        </Amount>
        <Divider color={commonColors.grey200} />
        <Amount fontWeight={700}>
          <p>
            {t('global.total')} {t('global.price')}:
          </p>
          <p>€{totalPrice}</p>
        </Amount>
        <Title>
          <p>{t('subscription_payment.excluding_vat')}</p>
        </Title>
      </TotalSection>
    </Wrapper>
  );
};

export default SelectedEmployees;
