import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const HeaderStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;

  > div {
    display: flex;
    flex-direction: column;

    h1 {
      color: ${commonColors.grey900};
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.28px;
      text-transform: capitalize;
      margin-bottom: 8px;
    }

    p {
      color: ${commonColors.grey700};
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.09px;
    }
  }
  > a {
    text-decoration: none;
    > button {
      min-width: fit-content;
      width: fit-content;
      padding: 10px 24px;
    }
  }
`;
