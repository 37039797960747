import React, { FC, useEffect, useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';

import { useAppSelector } from 'application/hooks/redux';
import { useSubscriptionPaymentMethods } from 'application/hooks/useSubscriptionPaymentMethods';

import { useStripeLoader } from 'features/subscription-payment/hooks/useStripeLoader';

import StripePaymentForm from '../StripePaymentForm';

import { StripePaymentProps, StripeTheme } from './models';
import { Wrapper } from './styles';

const StripePayment: FC<StripePaymentProps> = ({ onPaymentSuccess }) => {
  const { clientSecret, stripeAccountId } = useAppSelector(
    (state) => state.professional,
  );
  const { getPaymentMethods } = useSubscriptionPaymentMethods();
  const { getStripePromise } = useStripeLoader();
  const { paymentMethods, subscription } = useAppSelector(
    (state) => state.professional,
  );

  const appearance = {
    theme: 'stripe' as StripeTheme,
  };
  const options = {
    clientSecret,
    appearance,
  };

  const stripePromise = useMemo(() => {
    return stripeAccountId ? getStripePromise(stripeAccountId) : null;
  }, [stripeAccountId]);

  useEffect(() => {
    if (stripeAccountId) {
      getPaymentMethods(stripeAccountId);
    }
  }, [stripeAccountId]);

  return (
    <Wrapper>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <StripePaymentForm
            price={String(subscription?.price || 0)}
            clientSecret={clientSecret}
            paymentMethods={paymentMethods}
            onPaymentSuccess={onPaymentSuccess}
          />
        </Elements>
      )}
    </Wrapper>
  );
};

export default StripePayment;
