import { ClinicAddressResponseDto } from '@docbay/schemas';

export const formatAddress = (
  roomText: string,
  floorText: string,
  address?: ClinicAddressResponseDto,
) => {
  if (!address) {
    return '';
  }

  const country = address.country;
  const city = address.city;
  const zipCode = address.zipCode;
  const streetAddress = address.streetAddress;
  const floor = address.floor
    ? `${address.floor} ${floorText.toString().toLowerCase()}`
    : '';
  const room = address.room
    ? `${address.room} ${roomText.toString().toLowerCase()}`
    : '';

  const addressFiltered = [
    streetAddress,
    room,
    floor,
    zipCode,
    city,
    country,
  ].filter((item) => !!item);

  return addressFiltered.join(', ');
};
