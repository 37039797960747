import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import {
  ProfessionalIcon,
  PhoneIcon,
  EmailIcon,
  CalendarIcon,
  EditIcon,
  ClinicIcon,
} from 'application/assets';

import { Wrapper, ItemsList, ItemStyled } from './styles';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { SecondaryButton, SuccessModal } from 'application/components';
import AddEmployeeModal from 'features/employee-management/components/AddEmployeeModal';
import {
  resetEmployeeForAction,
  setEmployeeForAction,
} from 'application/store/reducers/EmployeesManagement/EmployeesManagementSlice';
import { resetDepartmentForAction } from 'application/store/reducers/Departments/DepartmentsSlice';
import { fetchEmployeeById } from 'application/store/reducers/EmployeesManagement/actions';

const EmployeeInfo: FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [showEditModal, setShowEditModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { employeeProfile, edited } = useAppSelector(
    (state) => state.employeesManagement,
  );

  useEffect(() => {
    if (edited) {
      setShowEditModal(false);
      setShowSuccessModal(true);
    }
  }, [edited]);

  const fullName = useMemo(() => {
    const name = [employeeProfile?.firstName, employeeProfile?.lastName]
      .filter((item) => !!item)
      .join(' ');
    return name;
  }, [employeeProfile?.firstName, employeeProfile?.lastName]);

  const dateOfBirth = useMemo(() => {
    if (!employeeProfile?.dateOfBirth) return '';
    const formattedDate = dayjs(employeeProfile.dateOfBirth).format(
      'DD/MM/YYYY',
    );
    const yearsOld =
      dayjs().get('years') - dayjs(employeeProfile.dateOfBirth).get('years');

    return `${formattedDate} (${yearsOld} ${t('global.years')})`;
  }, [employeeProfile?.dateOfBirth, i18n.language, t]);

  const handleSetEditedPatientId = () => {
    dispatch(setEmployeeForAction(employeeProfile));
    setShowEditModal(true);
  };

  const handleCloseModal = () => {
    dispatch(resetEmployeeForAction());
    dispatch(resetDepartmentForAction());
    setShowEditModal(false);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    dispatch(fetchEmployeeById(employeeProfile?.id!));
    dispatch(resetEmployeeForAction());
    dispatch(resetDepartmentForAction());
  };

  const modalText = useMemo(() => {
    return employeeProfile
      ? `${employeeProfile.firstName} ${employeeProfile.lastName}`
      : '';
  }, [employeeProfile]);

  return (
    <Wrapper>
      <ItemsList>
        <div>
          {fullName && (
            <ItemStyled>
              <ProfessionalIcon />
              <p>
                <span>{t('global.name')}</span>
                {fullName}
              </p>
            </ItemStyled>
          )}
          {dateOfBirth && (
            <ItemStyled>
              <CalendarIcon />
              <span>{t('global.date_of_birth')}</span>
              <p>{dateOfBirth}</p>
            </ItemStyled>
          )}
          {employeeProfile?.phone && (
            <ItemStyled>
              <PhoneIcon />
              <p>
                <span>{t('global.phone')}</span>
                {employeeProfile?.phone}
              </p>
            </ItemStyled>
          )}
        </div>
        <div>
          {' '}
          {employeeProfile?.department && (
            <ItemStyled>
              <ClinicIcon />
              <p>
                <span>{t('global.department')}</span>
                {employeeProfile?.department?.name}
              </p>
            </ItemStyled>
          )}
          {employeeProfile?.jobPosition && (
            <ItemStyled>
              <EmailIcon />
              <p>
                <span>{t('global.position')}</span>
                {employeeProfile?.jobPosition}
              </p>
            </ItemStyled>
          )}
        </div>
      </ItemsList>
      <SecondaryButton
        styleType="color"
        type="button"
        onClick={handleSetEditedPatientId}
      >
        <EditIcon />
        {t('global.edit')}
      </SecondaryButton>
      <AddEmployeeModal
        opened={showEditModal}
        onClose={handleCloseModal}
        isEdit={true}
      />
      <SuccessModal
        opened={showSuccessModal}
        onClose={handleCloseSuccessModal}
        firstText={t('tableActions.employee')}
        boldText={modalText}
        actionText={t('tableActions.edited') || ''}
      />
    </Wrapper>
  );
};

export default EmployeeInfo;
