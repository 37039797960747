import {
  ProfessionalCompanySubscriptionPaymentInitializationDto,
  ProfessionalSubscriptionPaymentResponseDto,
} from '@docbay/schemas';

import axiosInstance from './axiosInstance';

export const ProfessionalAPI = {
  subscriptionPayment: (
    data: ProfessionalCompanySubscriptionPaymentInitializationDto,
  ) => {
    return axiosInstance.post<ProfessionalSubscriptionPaymentResponseDto>(
      '/professionals/company-subscriptions/payment',
      data,
    );
  },
  fetchProfessionalSubscriptionById: (subscriptionId: string) => {
    return axiosInstance.get(`/professionals/subscriptions/${subscriptionId}`);
  },
  getPaymentMethodsForStripeAccount(stripeAccountId: string) {
    return axiosInstance.get(
      `/hr-departments/payment-methods/from-stripe-account/${stripeAccountId}`,
    );
  },
};
