import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { HomeWorkIcon, LocationOnIcon, CursorIcon } from 'application/assets';
import { formatAddress } from 'application/helpers/address';
import { useClinicDistance } from 'application/hooks/useClinicDistance';

import { LocationsOfPracticeProps } from './models';
import { Wrapper, TabsSection, TabItem, ClinicInfoStyled } from './styles';

const LocationsOfPractice: FC<LocationsOfPracticeProps> = ({
  clinics,
  selectedClinic,
  setSelectedClinic,
}) => {
  const { t } = useTranslation();

  const distance = useClinicDistance({
    latitude: selectedClinic?.clinic?.latitude,
    longitude: selectedClinic?.clinic?.longitude,
  });

  const showAddress = !selectedClinic?.clinic?.address?.hideAddress;
  const showDistance = !!distance.length && showAddress;

  const address = formatAddress(
    t('global.room'),
    t('global.floor'),
    selectedClinic?.clinic?.address,
  );

  return (
    <Wrapper>
      <TabsSection>
        <h1>{t('doctor_profile.locations_of_practice')}</h1>
        <div>
          {clinics.map((clinic, index) => (
            <TabItem
              key={clinic.id}
              type="button"
              active={selectedClinic?.id === clinic.id}
              onClick={() => setSelectedClinic(clinic)}
            >
              {t('global.clinic')} {index + 1}
            </TabItem>
          ))}
        </div>
      </TabsSection>
      <ClinicInfoStyled>
        <div>
          <HomeWorkIcon />
          <p>{selectedClinic?.clinic?.name}</p>
        </div>
        {showAddress && (
          <div>
            <LocationOnIcon />
            <p>{address}</p>
          </div>
        )}
        {showDistance && (
          <div>
            <CursorIcon />
            <p>{distance}</p>
          </div>
        )}
      </ClinicInfoStyled>
    </Wrapper>
  );
};

export default LocationsOfPractice;
