import { styled } from '@mui/system';
import { commonColors } from 'application/theme';

export const ModalStyled = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;

  h1 {
    color: ${commonColors.grey900};
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.2px;
    margin: 12px 0 32px;
  }

  > button {
    width: 186px;
  }
`;
