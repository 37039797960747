import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyStateSearchIcon } from 'application/assets';

import { Wrapper } from './styles';

const EmptyState: FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <EmptyStateSearchIcon />
      <h1>{t('find_doctor.empty_state.title')}</h1>
      <div>
        <p>{t('find_doctor.empty_state.text_1')}</p>
        <p>{t('find_doctor.empty_state.text_2')}</p>
      </div>
    </Wrapper>
  );
};

export default EmptyState;
