import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InitialState } from './models';
import { DashboardActions } from './actions';
import { HrDepartmentDashboardListResponseDto } from '@docbay/schemas';

const initialState: InitialState = {
  employees: [],
  error: '',
  total: 0,
  page: 1,
  totalPages: 0,
  filtersClinics: [],
  filtersProfessionals: [],
  filtersDepartments: [],
  filtersLocations: [],
};

const dashboardEmployeesSlice = createSlice({
  name: 'dashboardEmployeesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get dashboard employees
    builder.addCase(
      DashboardActions.getDashboardEmployees.fulfilled,
      (state, action) => {
        state.employees = action.payload.entities;
        state.total = action.payload.total || 0;
        state.totalPages = action.payload.totalPages || 0;
        state.page = action.payload.page || 1;
      },
    );
    // get dashboard filters clinics
    builder.addCase(
      DashboardActions.getDashboardFiltersClinics.fulfilled,
      (state, action) => {
        state.filtersClinics = action.payload;
      },
    );
    // get dashboard filters professionals
    builder.addCase(
      DashboardActions.getDashboardFiltersProfessionals.fulfilled,
      (state, action) => {
        state.filtersProfessionals = action.payload;
      },
    );
    // get dashboard filters departments
    builder.addCase(
      DashboardActions.getDashboardFiltersDepartments.fulfilled,
      (state, action) => {
        state.filtersDepartments = action.payload.entities;
      },
    );
    // get dashboard filters locations
    builder.addCase(
      DashboardActions.getDashboardFiltersLocations.fulfilled,
      (state, action) => {
        state.filtersLocations = action.payload.entities;
      },
    );
  },
});

export default dashboardEmployeesSlice.reducer;
