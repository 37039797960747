import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const FilterSectionStyled = styled.div`
  border-bottom: 1px solid ${commonColors.grey200};
  background: ${commonColors.white};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  > div:first-of-type {
    display: flex;
    align-items: center;

    justify-content: space-between;

    > div:first-of-type {
      width: 224px;
    }

    > div:last-of-type {
      width: 324px;
    }
  }
`;
