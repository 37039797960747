import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SecondaryButton } from 'application/components';
import { HelpIcon } from 'application/assets/index';
import { DocBaySupportLink } from 'application/constants/externalLinks';

import { HeaderStyled } from './styles';

const Header: FC = () => {
  const { t } = useTranslation();

  return (
    <HeaderStyled>
      <div>
        <h1>{t('benefit_plans.title')}</h1>
        <p>{t('benefit_plans.description')}</p>
      </div>
      <Link to={DocBaySupportLink} target="_blank">
        <SecondaryButton styleType={'color'} type="button">
          <HelpIcon />
          {t('header.help')}
        </SecondaryButton>
      </Link>
    </HeaderStyled>
  );
};

export default Header;
