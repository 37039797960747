import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import MyProfile from 'application/components/MyProfile';
import { PathNames } from 'application/routes/constants';
import { getCurrentRoutes } from 'application/routes/routes';
import { RoutesProps } from 'application/constants/routes';

import { Wrapper, AccordionStyled } from './styles';
import SecondMenuItem from '../SecondMenuItem';

const SecondMenu: FC = () => {
  const location = useLocation();
  const routes: RoutesProps[] = getCurrentRoutes();
  const isSettingPage = location.pathname.includes(PathNames.settings);
  const showMenu = isSettingPage;

  const locationEndIndex = location.pathname.slice(1).indexOf('/');
  const locationMainPath = location.pathname.slice(0, locationEndIndex);

  const routesFiltered = routes.filter(
    (item) => item.path.includes(locationMainPath) && item.isSecondMenuItem,
  );

  return (
    <Wrapper showMenu={showMenu}>
      <MyProfile />
      <AccordionStyled>
        {routesFiltered.map((item, index) => (
          <SecondMenuItem
            path={item.path}
            name={item.name || ''}
            key={index}
            Image={item.image}
          />
        ))}
      </AccordionStyled>
    </Wrapper>
  );
};

export default SecondMenu;
