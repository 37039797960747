import React, { FC } from 'react';

import { DividerProps } from '@mui/material';
import { DividerStyled } from './styles';

const Divider: FC<DividerProps> = ({ color }) => {
  return <DividerStyled color={color} />;
};

export default Divider;
