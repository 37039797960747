import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  margin: 32px 0 16px;
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 20px;
  color: ${commonColors.grey800};
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid ${commonColors.grey300};
  padding-bottom: 20px;
`;

export const NameStyled = styled.h1`
  color: ${commonColors.grey900};
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.07px;
`;

export const SpecializationStyled = styled.h2`
  color: ${commonColors.grey700};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.05px;
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

export const AboutStyled = styled('div')`
  display: flex;
  height: 100%;
  width: 100%;

  > span {
    min-width: fit-content;
  }

  textarea {
    border: 0;
    outline: 0;
    width: 100%;
    resize: none;
    field-sizing: content;
    background-color: transparent;

    color: ${commonColors.grey700};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.045px;
  }
`;

export const SubTitle = styled('span')`
  height: 28px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  color: ${commonColors.grey900};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.045px;
`;

export const Description = styled(SubTitle)`
  color: ${commonColors.grey700};
  font-weight: 500;
  word-break: break-word;
`;
