import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface CarouselProps {
  showDots: boolean;
}

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  padding: 64px;
  height: 615px;
  width: 65%;
  flex-direction: column;
  border-radius: 8px;
  background: ${commonColors.white};
  box-shadow: 0px 10px 45px 0px rgba(14, 40, 83, 0.07);

  > div {
    > svg {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 12px;
      top: 12px;
      cursor: pointer;

      path {
        fill: ${commonColors.grey500};
      }
    }
  }
`;

export const Carousel = styled.div<CarouselProps>`
  width: 100%;

  .slick-cloned {
    display: ${({ showDots }) => (showDots ? 'block' : 'none')};
  }

  .slick-arrow {
    display: ${({ showDots }) => (showDots ? 'flex' : 'none')} !important;
  }

  .slick-dots.slick-thumb {
    display: ${({ showDots }) => (showDots ? 'block' : 'none')} !important;
  }

  .slick-prev:before,
  .slick-next:before {
    display: none;
  }

  .slick-prev {
    left: -44px;
  }

  .slick-next {
    right: -44px;
  }

  .slick-dots {
    bottom: -86px;
    display: flex !important;
    justify-content: center;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    display: flex;
    overflow-x: auto;

    .slick-active {
      > a > div > img {
        border: 2px solid ${commonColors.blue800};
      }
    }
    li {
      width: 64px;
      height: 64px;
      margin: 0 4.5px;
      border-radius: 2px;
    }
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const PreviewImageContainer = styled.div`
  width: 64px;
  height: 64px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;

  img {
    width: 100%;
    height: 100%;
    border-radius: 2.874px;
    object-fit: cover;
  }
`;

export const CustomArrow = styled('div')`
  display: flex !important;
  background: ${commonColors.grey100} !important;
  border-radius: 50% !important;
  height: 32px !important;
  width: 32px !important;
  align-items: center !important;
  justify-content: center !important;

  svg {
    path {
      fill: ${commonColors.grey900};
    }
  }
`;
