import React, { FC, memo, useMemo, useState } from 'react';

import {
  EmailIcon,
  SearchIcon,
  CloseIcon,
  EyeOpenIcon,
  EyeClosedIcon,
  AttentionIcon,
  InfoIcon,
  LocationOnIcon,
} from 'application/assets';

import { InputProps } from './models';
import { InputWrapper, StyledInput, LabelWrapper } from './styled';

const Input: FC<InputProps> = ({
  id,
  placeholder,
  label,
  disabled,
  errorMessage,
  hint,
  type,
  register,
  isFocused,
  onChange,
  onClear,
  value,
  autoComplete,
  showRequiredIcon,
  min,
  step,
  pattern,
  onKeyDown,
  hideErrorMessage = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputType = useMemo(() => {
    const currentType = showPassword ? 'text' : type;
    return type === 'location' ? 'text' : currentType;
  }, [type, showPassword]);

  return (
    <StyledInput isError={!!errorMessage} disabled={disabled} type={type}>
      {label && (
        <LabelWrapper>
          <label htmlFor={id}>{label}</label>
          {showRequiredIcon && <InfoIcon />}
        </LabelWrapper>
      )}
      <InputWrapper disabled={disabled} type={type}>
        {type === 'email' && <EmailIcon />}
        {type === 'search' && <SearchIcon />}
        {type === 'location' && <LocationOnIcon />}
        {register ? (
          <input
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            type={inputType}
            {...register(id)}
            autoFocus={isFocused}
            autoComplete={autoComplete}
            min={min}
            step={step}
            pattern={pattern}
            onKeyDown={onKeyDown}
            onWheel={(e) => e.currentTarget.blur()}
          />
        ) : (
          <input
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            type={inputType}
            value={value || ''}
            onChange={onChange}
            autoFocus={isFocused}
            autoComplete={autoComplete}
            min={min}
            step={step}
            pattern={pattern}
            onKeyDown={onKeyDown}
          />
        )}
        {type === 'email' && errorMessage && (
          <span>
            <AttentionIcon />
          </span>
        )}
        {type === 'search' && value && (
          <CloseIcon onClick={() => onClear && onClear()} />
        )}
        {type === 'password' && (
          <button
            type="button"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {showPassword ? (
              <EyeClosedIcon width={18} height={18} />
            ) : (
              <EyeOpenIcon width={18} height={18} />
            )}
          </button>
        )}
      </InputWrapper>
      {(hint || (errorMessage && !hideErrorMessage)) && (
        <span>{errorMessage ? errorMessage : hint}</span>
      )}
    </StyledInput>
  );
};

export default memo(Input);
