import React, { FC, useEffect, useState } from 'react';
import { EmployeeTableItemModules } from './modules';
import { StyledTr } from './styles';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { setAssignToPackage } from 'application/store/reducers/EmployeesManagement/EmployeesManagementSlice';
import Checkbox from 'application/components/Checkbox';

const EmployeeTableItem: FC<EmployeeTableItemModules> = ({ data }) => {
  const [checked, setChecked] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const { assignToPackage } = useAppSelector(
    (state) => state.employeesManagement,
  );

  useEffect(() => {
    const isActive = assignToPackage?.some((item) => item.id === data.id);

    setChecked(isActive);
  }, [assignToPackage]);

  const handleChangeCheckbox = (value: boolean) => {
    if (value) {
      const newItem = {
        id: data.id,
        name: `${data.firstName} ${data.lastName}`,
      };

      dispatch(setAssignToPackage([...assignToPackage, newItem]));
    } else {
      const filteredEmployees = assignToPackage.filter(
        (item) => item.id !== data.id,
      );

      dispatch(setAssignToPackage(filteredEmployees));
    }
  };

  return (
    <>
      <StyledTr>
        <td>
          <Checkbox
            id={`row_${data.id}`}
            onChange={(value) => handleChangeCheckbox(value)}
            checked={checked}
          />
        </td>
        <td>
          {data.firstName} {data.lastName}
        </td>
        <td>{data.phone}</td>
        <td>{data.department?.name || ''}</td>
        <td>{data.jobPosition}</td>
      </StyledTr>
    </>
  );
};

export default EmployeeTableItem;
