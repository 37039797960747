import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const MainText = styled.div`
  font-family: AcidGrotesk-Bold;
  color: ${commonColors.grey900};
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  margin-top: 12px;
`;

export const StyledModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  min-width: 500px;
  padding: 32px;
  flex-direction: column;
  border-radius: 8px;
  background: ${commonColors.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);

  > svg {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 12px;
    top: 12px;
    cursor: pointer;

    path {
      fill: ${commonColors.grey500};
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      color: ${commonColors.grey900};
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      margin: 12px 0 32px 0;
      > span {
        font-weight: 600;
      }
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  a {
    text-decoration: none;
    margin-left: 16px;
    width: inherit;
  }

  button {
    padding: 12px 24px;
    white-space: nowrap;
  }
`;
