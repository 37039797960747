import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 16px 20px 16px 112px;
  height: calc(100% - 32px);

  border-radius: 8px;
  background: ${commonColors.white};
  box-shadow: 0px 10px 45px 0px rgba(14, 40, 83, 0.07);
`;

export const HeaderStyled = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  h1 {
    color: ${commonColors.grey900};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.2px;
  }

  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;

      path {
        fill: ${commonColors.grey700};
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

export const PackStyled = styled.div`
  display: flex;
  align-items: center;

  h2 {
    color: ${commonColors.grey600};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.08px;
  }

  p {
    margin: 0 8px 0 4px;
    color: ${commonColors.grey600};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.07px;
  }

  h3 {
    color: ${commonColors.green900};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.07px;
  }
`;

export const TableWrapper = styled.div`
  border-radius: 12px;
  border: 1px solid var(--Grey-200, #eaecf0);
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);
`;

export const TableStyled = styled.table`
  width: 100%;
  border-radius: 12px;
  border-collapse: separate;

  > thead {
    tr {
      th {
        vertical-align: middle;
        text-align: start;
        padding: 12px 24px;
        color: ${commonColors.grey600};
        font-size: 14px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: 0.07px;
        background: ${commonColors.grey50};
        border-bottom: 1px solid ${commonColors.grey200};

        &:first-of-type {
          border-top-left-radius: 12px;
        }
        &:last-of-type {
          border-top-right-radius: 12px;
        }
      }
    }
  }
  > tbody {
    max-height: 200px;
    overflow-y: auto;
    > tr {
      &:last-child {
        > td {
          &:first-of-type {
            border-bottom-left-radius: 12px;
          }
          &:last-of-type {
            border-bottom-right-radius: 12px;
          }
        }
      }
    }
  }
`;

export const Title = styled.h1`
  color: ${commonColors.grey900};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.2px;

  span {
    color: ${commonColors.blue800};
  }
`;
