export enum CommonErrors {
  requiredField = 'This field is required',
  emailFormat = 'Check Email format',
}

export enum ERROR_CODE {
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  UNIT_OF_MEASUREMENT_NOT_FOUND = 'UNIT_OF_MEASUREMENT_NOT_FOUND',
  UNIT_OF_MEASUREMENT_ALREADY_EXISTS = 'UNIT_OF_MEASUREMENT_ALREADY_EXISTS',
  UNIT_OF_MEASUREMENT_ALREADY_IN_USE = 'UNIT_OF_MEASUREMENT_ALREADY_IN_USE',
  HEALTH_METRIC_NOT_FOUND = 'HEALTH_METRIC_NOT_FOUND',
  HR_COMPANY_NOT_FOUND = 'HR_COMPANY_NOT_FOUND',
  MULTIPLE_CLINIC_OWNER_SUBSCRIPTIONS_NOT_ALLOWED = 'MULTIPLE_CLINIC_OWNER_SUBSCRIPTIONS_NOT_ALLOWED',
  PATIENT_NOT_FOUND = 'PATIENT_NOT_FOUND',
  CLINIC_PROFESSIONAL_NOT_FOUND = 'CLINIC_PROFESSIONAL_NOT_FOUND',
  APPOINTMENT_REQUEST_ALREADY_EXISTS = 'APPOINTMENT_REQUEST_ALREADY_EXISTS',
  HR_DEPARTMENT_EMPLOYEE_ALREADY_EXISTS = 'HR_DEPARTMENT_EMPLOYEE_ALREADY_EXISTS',
  HR_DEPARTMENT_EMPLOYEE_NOT_FOUND = 'HR_DEPARTMENT_EMPLOYEE_NOT_FOUND',
  HR_DEPARTMENT_HAS_EMPLOYEES_CONNECTED = 'HR_DEPARTMENT_HAS_EMPLOYEES_CONNECTED',
  HR_LOCATION_HAS_EMPLOYEES_CONNECTED = 'HR_LOCATION_HAS_EMPLOYEES_CONNECTED',
  HR_LOCATION_NOT_FOUND = 'HR_LOCATION_NOT_FOUND',
  HR_LOCATION_NAME_ALREADY_EXISTS = 'HR_LOCATION_NAME_ALREADY_EXISTS',
  HR_DEPARTMENT_NOT_FOUND = 'HR_DEPARTMENT_NOT_FOUND',
  HR_DEPARTMENT_ALREADY_EXISTS = 'HR_DEPARTMENT_ALREADY_EXISTS',
  USER_WITH_EMAIL_ALREADY_EXISTS = 'USER_WITH_EMAIL_ALREADY_EXISTS',
  HR_ADMIN_EXISTS_BY_PHONE = 'HR_ADMIN_EXISTS_BY_PHONE',
  HR_USER_EXISTS_BY_PHONE = 'HR_USER_EXISTS_BY_PHONE',
  HR_ADMIN_NOT_FOUND = 'HR_ADMIN_NOT_FOUND',
  HR_DEPARTMENT_HR_NOT_FOUND = 'HR_DEPARTMENT_HR_NOT_FOUND',
  USER_IS_NOT_HR_ADMIN = 'USER_IS_NOT_HR_ADMIN',
  USER_IS_NOT_ALLOWED_TO_DELETE_HIMSELF = 'USER_IS_NOT_ALLOWED_TO_DELETE_HIMSELF',
}

export const errorCodesList = [
  ERROR_CODE.USER_NOT_FOUND,
  ERROR_CODE.UNIT_OF_MEASUREMENT_NOT_FOUND,
  ERROR_CODE.UNIT_OF_MEASUREMENT_ALREADY_EXISTS,
  ERROR_CODE.UNIT_OF_MEASUREMENT_ALREADY_IN_USE,
  ERROR_CODE.HEALTH_METRIC_NOT_FOUND,
  ERROR_CODE.HR_COMPANY_NOT_FOUND,
  ERROR_CODE.MULTIPLE_CLINIC_OWNER_SUBSCRIPTIONS_NOT_ALLOWED,
  ERROR_CODE.PATIENT_NOT_FOUND,
  ERROR_CODE.CLINIC_PROFESSIONAL_NOT_FOUND,
  ERROR_CODE.APPOINTMENT_REQUEST_ALREADY_EXISTS,
  ERROR_CODE.HR_DEPARTMENT_EMPLOYEE_ALREADY_EXISTS,
  ERROR_CODE.HR_DEPARTMENT_EMPLOYEE_NOT_FOUND,
  ERROR_CODE.HR_DEPARTMENT_HAS_EMPLOYEES_CONNECTED,
  ERROR_CODE.HR_LOCATION_HAS_EMPLOYEES_CONNECTED,
  ERROR_CODE.HR_LOCATION_NOT_FOUND,
  ERROR_CODE.HR_LOCATION_NAME_ALREADY_EXISTS,
  ERROR_CODE.HR_DEPARTMENT_NOT_FOUND,
  ERROR_CODE.HR_DEPARTMENT_ALREADY_EXISTS,
  ERROR_CODE.USER_WITH_EMAIL_ALREADY_EXISTS,
  ERROR_CODE.HR_ADMIN_EXISTS_BY_PHONE,
  ERROR_CODE.HR_USER_EXISTS_BY_PHONE,
  ERROR_CODE.HR_ADMIN_NOT_FOUND,
  ERROR_CODE.HR_DEPARTMENT_HR_NOT_FOUND,
  ERROR_CODE.USER_IS_NOT_HR_ADMIN,
  ERROR_CODE.USER_IS_NOT_ALLOWED_TO_DELETE_HIMSELF,
];
