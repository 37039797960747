import { StorageFields } from 'application/constants';

export const geolocationService = {
  saveGeolocation(latitude: number, longitude: number) {
    localStorage.setItem(StorageFields.latitude, JSON.stringify(latitude));
    localStorage.setItem(StorageFields.longitude, JSON.stringify(longitude));
  },

  getGeolocation() {
    const latitudeStorage = localStorage.getItem(StorageFields.latitude);
    const longitudeStorage = localStorage.getItem(StorageFields.longitude);

    if (!latitudeStorage || !longitudeStorage) {
      return { latitude: '', longitude: '' };
    }

    const latitude = JSON.parse(latitudeStorage || '');
    const longitude = JSON.parse(longitudeStorage || '');
    return { latitude, longitude };
  },
};
