import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';

import { CountryActions } from 'application/store/reducers/Country/actions';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import {
  getCountryCode,
  setCountryCode,
} from 'application/helpers/sessionStorage/auth';

import Flag from '../Flag';

import {
  Country,
  CountryWrapper,
  MenuItemStyled,
  SelectStyled,
  Title,
} from './styles';

const defaultCountry = process.env.NEXT_PUBLIC_DOCBAY_DEFAULT_COUNTRY;

interface Props {
  showCountryName?: boolean;
  keyName: string;
}

const CountrySwitcher: FC<Props> = ({ showCountryName, keyName }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { countries, currentUserCountry } = useAppSelector(
    (state) => state.countries,
  );

  const [currentCountry, setCurrentCountry] = useState('');
  const [countryPick, setLanguagePick] = useState('');

  const setUserCountryCode = (code: string) => {
    const currentCountryR = countries.find(
      (item) => item.code.toLowerCase() === code.toLowerCase(),
    );

    if (currentCountryR) {
      setCurrentCountry(currentCountryR?.code || defaultCountry!);
      setLanguagePick(currentCountryR?.name || '');
      setCountryCode(currentCountryR?.code.toUpperCase() || defaultCountry!);
    }
  };

  useEffect(() => {
    const localeLang = navigator.language.slice(0, 2);
    const countryCode = getCountryCode();
    const chosenCountry = countryCode || localeLang || '';

    setUserCountryCode(chosenCountry);
  }, [countries]);

  useEffect(() => {
    setUserCountryCode(currentUserCountry);
  }, [currentUserCountry]);

  useEffect(() => {
    !countries.length && dispatch(CountryActions.getSupportedCountries());
  }, [countries]);

  const handleChange = async (event: SelectChangeEvent<unknown>) => {
    const countryCode = event.target.value as string;

    dispatch(
      CountryActions.setUserCountry({
        countryIso: countryCode.toUpperCase(),
      }),
    );
  };

  const currentCountrySection = useMemo(() => {
    return (
      <Country>
        <Flag code={currentCountry!.toLowerCase()} />
        {showCountryName && countryPick}
      </Country>
    );
  }, [currentCountry, currentUserCountry, keyName, countryPick]);

  return (
    <CountryWrapper key={keyName}>
      {showCountryName && (
        <Title>
          <p>{t('footer.country')}</p>
        </Title>
      )}

      <SelectStyled
        value={currentCountry}
        onChange={handleChange}
        variant="standard"
        disableUnderline
        displayEmpty
        renderValue={() => currentCountrySection}
      >
        {countries?.map((item) => {
          return (
            <MenuItemStyled
              value={item.code}
              key={item.code}
              selected={currentCountry === item.name}
            >
              <Flag code={item.code.toLowerCase()} />
              {item.name}
            </MenuItemStyled>
          );
        })}
      </SelectStyled>
    </CountryWrapper>
  );
};

export default CountrySwitcher;
