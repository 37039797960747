import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BenefitStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
  padding: 20px 32px;

  border-radius: 12px;
  border: 1px solid ${commonColors.grey200};
  background: ${commonColors.white};
  box-shadow: 1px 1px 0px 0px rgba(11, 37, 80, 0.17);

  > div {
    display: flex;
    flex-direction: column;

    svg {
      width: 24px;
      height: 24px;
    }

    h1 {
      margin: 4px 0 8px;
      color: ${commonColors.grey900};
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.2px;
    }

    p {
      color: ${commonColors.grey900};
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.07px;
    }
  }

  > a {
    text-decoration: none;
    button {
      width: fit-content;
      min-width: 188px;
    }
  }
`;
