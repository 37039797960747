import styled from '@emotion/styled';

import { commonColors } from 'application/theme';
import { StyledProps } from './models';

export const PaymentMethodsStyled = styled.div`
  display: grid;
  gap: 8px;

  h1 {
    margin-bottom: 8px;
    color: ${commonColors.grey900};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;

export const CardStyled = styled.button<StyledProps>`
  display: grid;
  gap: 12px;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid
    ${({ isActive }) =>
      isActive ? commonColors.blue300 : commonColors.grey300};
  background: ${({ isActive }) =>
    isActive ? commonColors.background : commonColors.white};

  &:hover {
    border: 1px solid ${commonColors.blue300};
    background: ${commonColors.background};
  }

  svg {
    min-height: 34px;
    height: 34px;
  }

  p {
    color: ${commonColors.grey900};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: 0.04px;
  }
`;
