import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { SpecializationsAPI } from 'integration/api/specializations';
import { RequestEnum } from '../request/model';

const fetchSpecializations = createAsyncThunk(
  RequestEnum.fetchSpecializations,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await SpecializationsAPI.fetchSpecializations();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const SpecializationsActions = { fetchSpecializations };
