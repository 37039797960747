import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { HelpBar } from 'application/components';
import { PathNames } from 'application/routes/constants';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { SpecializationsActions } from 'application/store/reducers/Specializations/actions';
import { requestSelector } from 'application/store/reducers/request/selectors';
import { resetSearchData } from 'application/store/reducers/HrDepartmentSearch/hrDepartmentSearchSlice';

import { Loader } from 'common';

import { Header, SearchGroup, DoctorsList } from './components';
import { Wrapper } from './styles';

const FindDoctor: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { fetchHrDepartmentSearch, quickSearch, fetchSpecializations } =
    useAppSelector(requestSelector);

  const isLoading =
    fetchHrDepartmentSearch.loading ||
    quickSearch.loading ||
    fetchSpecializations.loading;
  useEffect(() => {
    dispatch(SpecializationsActions.fetchSpecializations());
    return () => {
      dispatch(resetSearchData());
    };
  }, []);

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <HelpBar
        text={t('global.back') || ''}
        pathBack={PathNames.doctorSubscription}
      />
      <Header />
      <SearchGroup />
      <DoctorsList />
    </Wrapper>
  );
};

export default FindDoctor;
