import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface SearchInputWrapperProps {
  hasError: boolean;
}

interface OptionItemProps {
  selected: boolean;
}

export const Wrapper = styled.div<SearchInputWrapperProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 12px;
  width: 100%;
  position: relative;
  min-width: 220px;

  svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    path {
      fill: ${commonColors.grey500};
    }
  }
  > div:first-of-type {
    width: 100%;
  }
  input {
    border: ${({ hasError }) => (hasError ? '' : '0 !important')};
    &:focus {
      background: ${({ hasError }) =>
        hasError ? commonColors.error3 : commonColors.blue50} !important;
      border: 1px solid
        ${({ hasError }) =>
          hasError ? commonColors.error2 : commonColors.blue800} !important;
    }
    box-shadow: none !important;
  }
`;

export const OptionsList = styled('div')`
  display: grid;
  padding: 8px;
  gap: 4px;
  grid-template-rows: max-content;
  position: absolute;
  z-index: 10;
  top: 54px;
  max-height: 364px;
  overflow-y: auto;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${commonColors.grey300};
  background: ${commonColors.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  cursor: pointer;
  width: 360px;
`;

export const OptionItem = styled('div')<OptionItemProps>`
  display: flex;
  padding: 4px 6px;
  align-items: center;
  border-radius: 4px;
  gap: 8px;
  background: ${({ selected }) =>
    selected ? commonColors.blue100 : 'transparent'};

  color: ${commonColors.grey800};
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.08px;

  &:hover {
    background: ${commonColors.grey100};
  }
`;

export const DividerStyled = styled.div`
  height: 1px;
  background: ${commonColors.grey200};
`;

export const NameStyled = styled('div')`
  display: flex;
  align-items: center;

  > img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  > div:last-child {
    display: grid;
    gap: 2px;
    margin: 0 12px;

    > p {
      color: ${commonColors.grey900};
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
    }

    > span {
      color: ${commonColors.grey800};
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      letter-spacing: 0.07px;
    }
  }
`;

export const AvatarStyled = styled('div')`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background: ${commonColors.blueDark100};
  border-radius: 50%;

  > svg {
    path {
      fill: ${commonColors.grey900};
    }
  }
`;

export const SpecializationStyled = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;

  > span {
    color: ${commonColors.grey800};
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    letter-spacing: 0.07px;
  }
`;
