import { CpSubscriptionResponseDto } from '@docbay/schemas';

export enum AssignEmployees {
  employees = 'employees',
  currentPackage = 'currentPackage',
}

interface AssignEmployeeProps {
  id: string;
  name: string;
}

export const setAssignEmployees = (arr: AssignEmployeeProps[]) => {
  sessionStorage.setItem(AssignEmployees.employees, JSON.stringify(arr));
};

export const setCurrentPackage = (
  currentPackage: CpSubscriptionResponseDto,
) => {
  sessionStorage.setItem(
    AssignEmployees.currentPackage,
    JSON.stringify(currentPackage),
  );
};

export const getAssignEmployees = () => {
  const employees = sessionStorage.getItem(AssignEmployees.employees);

  return employees ? JSON.parse(employees) : [];
};

export const getCurrentPackage = () => {
  const currentPackage = sessionStorage.getItem(AssignEmployees.currentPackage);

  return currentPackage ? JSON.parse(currentPackage) : null;
};

export const clearPaymentData = () => {
  sessionStorage.removeItem(AssignEmployees.currentPackage);
  sessionStorage.removeItem(AssignEmployees.employees);
};
