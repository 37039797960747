import { createSlice } from '@reduxjs/toolkit';
import { SpecializationsActions } from './actions';
import { SpecializationsState } from './models';

const initialState: SpecializationsState = {
  specializations: [],
};

const specializationsSlice = createSlice({
  name: 'specializationsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //professional by id fetch
    builder.addCase(
      SpecializationsActions.fetchSpecializations.fulfilled,
      (state, action) => {
        state.specializations = action.payload;
      },
    );
  },
});

export default specializationsSlice.reducer;
