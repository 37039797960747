import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { handleError } from './errorService';

export const initialState = {
  error: null,
  message: null,
  isErrorModalVisible: false,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    logError: (state, action: PayloadAction<boolean>) => {
      state.isErrorModalVisible = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action: PayloadAction<any>) => {
        state.isErrorModalVisible = handleError(action);
      },
    );
  },
});

export const { logError } = errorSlice.actions;
