import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h2 {
    color: ${commonColors.grey800};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 33.6px;
    letter-spacing: 0.06px;
    margin-top: 24px;
  }

  h3 {
    color: ${commonColors.grey900};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: 0.045px;
    max-width: 450px;
    margin: 4px 0 32px;
  }

  button {
    max-width: 320px;
    width: 100%;
  }
`;
