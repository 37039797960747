import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper } from './styles';

const Header: FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <h1>{t('find_doctor.header.title')}</h1>
      <p>{t('find_doctor.header.sub_title')}</p>
    </Wrapper>
  );
};

export default Header;
