import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface Props {
  isCollapsed: boolean;
}
export const Wrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  position: relative;
  max-height: ${({ isCollapsed }) => (isCollapsed ? '64px' : 'auto')};
  overflow: ${({ isCollapsed }) => (isCollapsed ? 'hidden' : 'unset')};
`;

export const EmployeeItem = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  gap: 4px;
  background: ${commonColors.grey200};
  border-radius: 100px;
  font-size: 14px;

  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;

    path {
      fill: ${commonColors.grey600};
    }
  }
`;

export const AdditionalButton = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 16px;
  line-height: 32px;
  font-weight: 700;
  color: ${commonColors.blue800};
  cursor: pointer;
  background: ${commonColors.white};

  svg {
    path {
      fill: ${commonColors.blue800};
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -80px;
    width: 80px;
    height: 32px;
    background-color: rgba(89, 131, 252, 0);
    background-image: linear-gradient(
      90deg,
      rgba(89, 131, 252, 0) 0%,
      rgba(255, 255, 255, 1) 60%
    );
  }
`;

export const ClearButton = styled.div`
  font-size: 16px;
  line-height: 32px;
  font-weight: 700;
  color: ${commonColors.blue800};
  cursor: pointer;

  svg {
    path {
      fill: ${commonColors.blue800};
    }
  }
`;
