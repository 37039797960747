import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
`;

export const SortSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > h2 {
    color: ${commonColors.grey900};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.09px;
  }

  > div {
    width: 160px;

    h2 {
      margin-right: 0 !important;
    }

    svg {
      margin-left: 0 !important;
    }
  }
`;

export const ListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
