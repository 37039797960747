import {
  CommonEntitiesListResponse,
  PaginatedProfessionalHrDepartmentSearchCriteriaDto,
  ProfessionalQuickSearchResponse,
  ProfessionalResponseDto,
  QuickSearchQueryDto,
} from '@docbay/schemas';

import axiosInstance from './axiosInstance';

export const HrDepartmentSearchAPI = {
  fetchHrDepartmentSearch: (
    data: PaginatedProfessionalHrDepartmentSearchCriteriaDto,
  ) => {
    return axiosInstance.post<
      CommonEntitiesListResponse<ProfessionalResponseDto>
    >('/professionals/hr-department-search', data);
  },
  getHrDepartmentSearchProfessional: (professionalId: string) => {
    return axiosInstance.get<ProfessionalResponseDto>(
      `professionals/hr-department-search/${professionalId}`,
    );
  },
  quickSearch: (data: QuickSearchQueryDto) => {
    return axiosInstance.post<ProfessionalQuickSearchResponse>(
      '/professionals/hr-department-search/quick-search',
      data,
    );
  },
};
