import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from 'application/components';

import { HeaderStyled } from './styles';
import { Link } from 'react-router-dom';
import { PathNames } from 'application/routes/constants';

const Header: FC = () => {
  const { t } = useTranslation();

  return (
    <HeaderStyled>
      <div>
        <h1>{t('doctor_subscription.header.title')}</h1>
        <p>{t('doctor_subscription.header.description_1')}</p>
        <p>{t('doctor_subscription.header.description_2')}</p>
      </div>
      <Link to={PathNames.findDoctor}>
        <PrimaryButton type="button">
          {t('doctor_subscription.header.action')}
        </PrimaryButton>
      </Link>
    </HeaderStyled>
  );
};

export default Header;
