import styled from '@emotion/styled';
import { MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import { commonColors } from 'application/theme';
// import theme from 'theme';

interface Props {
  selected?: boolean;
}

export const CountryWrapper = styled(Box)`
  color: ${commonColors.menu};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Title = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }

  p {
    font-size: medium;
    font-weight: 900;
    color: ${commonColors.menu};
  }
`;

export const SelectStyled = styled(Select)`
  text-align: center;
  font-size: medium;
  background-color: transparent;

  p {
    line-height: 20px;
    color: ${commonColors.menu};
  }

  .MuiSelect-select {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }

  .MuiSelect-icon {
    display: block;
    top: 10%;
    margin-bottom: 3%;
    fill: ${commonColors.menu};
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  &:selected {
    background-color: transparent;
  }
`;

export const Country = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const MenuItemStyled = styled(MenuItem)<Props>`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: medium;

  color: ${commonColors.blue900};
  background-color: ${(props) =>
    props.selected ? commonColors.menu : 'transparent'};

  svg {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
  }
`;
