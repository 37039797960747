import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Modal } from '@mui/material';

import { CloseIcon, CommonErrorIcon } from 'application/assets';
import { useAppDispatch } from 'application/hooks/redux';
import { DocBaySupportLink } from 'application/constants/externalLinks';

import { logError } from 'common';

import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';

import { ButtonWrapper, MainText, StyledModal } from './styles';

const CommonErrorModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const closeErrorModal = () => {
    dispatch(logError(false));
  };

  return (
    <Modal open={true}>
      <StyledModal>
        <CloseIcon onClick={closeErrorModal} />
        <div>
          <CommonErrorIcon />
          <MainText>{t('global_error.text_title')}</MainText>
          <p>{t('global_error.text_description')}</p>
          <ButtonWrapper>
            <SecondaryButton onClick={closeErrorModal}>
              {t('global_error.try_again')}
            </SecondaryButton>
            <Link to={DocBaySupportLink} target="_blank">
              <PrimaryButton type="button">
                {t('global_error.support')}
              </PrimaryButton>
            </Link>
          </ButtonWrapper>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default CommonErrorModal;
