import {
  HrDepartmentDashboardRequestDto,
  HrDepartmentDashboardListResponseDto,
  ClinicBriefResponseDto,
  ProfessionalBriefResponseDto,
  HrDepartmentResponseDto,
  HrDepartmentLocationResponseDto,
  CommonEntitiesListResponse,
} from '@docbay/schemas';

import { DashboardFiltersDepartmentsParams } from 'application/constants/dashboard';

import axiosInstance from './axiosInstance';

export const DashboardAPI = {
  getDashboardEmployees: (data: HrDepartmentDashboardRequestDto) => {
    return axiosInstance.post<HrDepartmentDashboardListResponseDto>(
      '/hr-departments/dashboard/employees',
      data,
    );
  },
  getDashboardFiltersClinics: (search?: string) => {
    return axiosInstance.get<ClinicBriefResponseDto[]>(
      '/hr-departments/dashboard/filters/clinics',
      {
        params: {
          ...(search?.length ? { search } : {}),
        },
      },
    );
  },
  getDashboardFiltersProfessionals: (search?: string) => {
    return axiosInstance.get<ProfessionalBriefResponseDto[]>(
      '/hr-departments/dashboard/filters/professionals',
      {
        params: {
          ...(search?.length ? { search } : {}),
        },
      },
    );
  },
  getDashboardFiltersDepartments: (
    params: DashboardFiltersDepartmentsParams,
  ) => {
    const { search, ...restParams } = params;
    return axiosInstance.get<
      CommonEntitiesListResponse<HrDepartmentResponseDto>
    >('/hr-departments/dashboard/filters/departments', {
      params: {
        ...restParams,
        ...(search?.length ? { search } : {}),
      },
    });
  },
  getDashboardFiltersLocations: (search?: string) => {
    return axiosInstance.get<
      CommonEntitiesListResponse<HrDepartmentLocationResponseDto>
    >('/hr-departments/dashboard/filters/locations', {
      params: {
        ...(search?.length ? { search } : {}),
      },
    });
  },
};
