import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface Props {
  isError: boolean;
}

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  width: fit-content;
  border-radius: 8px;
  background: ${({ isError }) =>
    isError ? commonColors.error3 : commonColors.success4};
  gap: 4px;
  padding: 12px 12px 8px;

  > div:last-of-type {
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    line-height: 19.6px;
    display: flex;
    gap: 4px;

    span {
      font-weight: 600;
    }
  }

  .MuiSlider-root {
    height: 2px;
  }

  .MuiSlider-thumb {
    display: none;
  }

  .MuiSlider-track {
    border: 1px solid
      ${({ isError }) =>
        isError ? commonColors.warning2 : commonColors.success1};
  }
  .MuiSlider-rail {
    background-color: ${commonColors.grey200};
  }
`;
