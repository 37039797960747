import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterSectionProps } from './modules';
import { FilterSectionStyled } from './styles';
import { Dropdown, Input } from 'application/components';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { getDepartmentsByCompany } from 'application/store/reducers/Departments/actions';
import AssignedEmployees from '../AssignedEmployees';

const FilterSection: FC<FilterSectionProps> = ({
  selectedDepartments,
  searchItem,
  setSearchItem,
  setDepartments,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { departments } = useAppSelector((state) => state.departments);
  const { total } = useAppSelector((state) => state.employeesManagement);

  useEffect(() => {
    dispatch(getDepartmentsByCompany());
  }, []);

  const departmentsOptions = useMemo(() => {
    if (!departments.length) return [];

    const options = departments.map((item) => ({
      label: item.name,
      value: item.id,
    }));

    return options;
  }, [departments]);

  return (
    <FilterSectionStyled>
      <div>
        <Dropdown
          id={'departments'}
          value={selectedDepartments}
          onChange={(value) => {
            setDepartments(value as string[]);
          }}
          placeholder={t('staff.department_dropdown_placeholder')}
          options={departmentsOptions}
          isMulti
          disabled={!total && !selectedDepartments.length}
        />
        <Input
          id={'search'}
          type="search"
          placeholder={t('staff.search_employee_placeholder') || ''}
          value={searchItem}
          onChange={(e) => setSearchItem(e.target.value)}
          onClear={() => setSearchItem('')}
          disabled={!total && !searchItem.length}
        />
      </div>
      <AssignedEmployees />
    </FilterSectionStyled>
  );
};

export default FilterSection;
