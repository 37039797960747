import {
  CommonEntitiesListResponse,
  CompanyPurchasedSubscriptionExtendedResponse,
  CompanyPurchasedSubscriptionResponse,
} from '@docbay/schemas';

import axiosInstance from './axiosInstance';

export const SubscriptionsAPI = {
  getSubscriptions: (page: number) => {
    return axiosInstance.get<
      CommonEntitiesListResponse<CompanyPurchasedSubscriptionResponse>
    >('/hr-departments/subscriptions/purchased', {
      params: { limit: 10, page },
    });
  },
  getSubscriptionById: (companyPurchasedSubscriptionId: string) => {
    return axiosInstance.get<CompanyPurchasedSubscriptionExtendedResponse>(
      `/hr-departments/subscriptions/purchased/${companyPurchasedSubscriptionId}`,
    );
  },
  deleteSubscription: (companyPurchasedSubscriptionId: string) => {
    return axiosInstance.delete(
      `/hr-departments/subscriptions/purchased/${companyPurchasedSubscriptionId}`,
    );
  },
};
