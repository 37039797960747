import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface DropdownStyledProps {
  isActive?: boolean;
  disabled?: boolean;
}

interface OptionItemProps {
  selected: boolean;
}

export const DropdownStyled = styled.div<DropdownStyledProps>`
  display: grid;
  gap: 6px;
  width: 100%;
  position: relative;

  > label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.08px;
    color: ${({ disabled }) =>
      disabled ? commonColors.grey400 : commonColors.grey600};
  }

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.07px;
    color: ${({ disabled }) =>
      disabled ? commonColors.grey400 : commonColors.grey600};
  }
  > section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
    width: 100%;
    height: 44px;
    background: ${({ isActive, disabled }) =>
      isActive
        ? commonColors.blue50
        : commonColors[disabled ? 'grey50' : 'white']};
    border: 1px solid
      ${({ isActive }) =>
        isActive ? commonColors.blue800 : commonColors.grey300};
    box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
    border-radius: 8px;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: ${commonColors.grey700};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    > span {
      color: ${({ disabled }) =>
        disabled ? commonColors.grey300 : commonColors.grey500};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    > svg {
      margin-left: 12px;
      transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : '')};
      path {
        fill: ${({ disabled }) =>
          disabled ? commonColors.grey300 : commonColors.grey700};
      }
    }
  }
`;

export const OptionsList = styled.div`
  display: grid;
  padding: 12px;
  gap: 4px;
  grid-template-rows: max-content;
  position: absolute;
  z-index: 10;
  top: 45px;
  max-height: 390px;
  min-width: 440px;
  overflow-y: auto;
  width: 100%;
  border-radius: 8px;
  border-radius: 8px;
  background: ${commonColors.white};
  box-shadow: 0px 10px 45px 0px rgba(14, 40, 83, 0.07);
  box-shadow: 0px 10px 45px 0px rgba(14, 40, 83, 0.07);
  cursor: pointer;
`;

export const OptionCheckBox = styled.label<OptionItemProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  margin-top: 12px;
  border-radius: 4px;
  cursor: pointer;
  background: ${({ selected }) =>
    selected ? commonColors.blue100 : 'transparent'};

  &:hover {
    background: ${commonColors.grey100};
  }

  > div:first-child {
    display: flex;
    align-items: center;
  }

  p {
    color: ${commonColors.grey900};
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
  }

  > div:last-child {
    margin-left: 12px;
    label {
      padding: 0px;
    }
  }
`;

export const ClinicImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${commonColors.blueDark100};
  margin-right: 16px;
  color: ${commonColors.white};

  > svg {
    path {
      fill: ${commonColors.grey900};
    }
  }

  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

export const SpecializationsList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 400px;

  > p {
    color: ${commonColors.grey600};
    font-weight: 400;
    margin-right: 2px;
  }
`;
