import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SecondMenuItemProps } from './models';
import { MenuItemStyled } from './styles';

const SecondMenuItem: FC<SecondMenuItemProps> = ({ path, name, Image }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isCurrentPage = location.pathname === path;
  const isPageActive = location.pathname.includes(path);

  const handleChangePath = () => {
    if (!isCurrentPage) {
      path && navigate(path);
    }
  };

  return (
    <MenuItemStyled>
      <button
        onClick={handleChangePath}
        className={`${isPageActive ? 'active' : ''}`}
      >
        <>
          {Image && <Image />}
          {name}
        </>
      </button>
    </MenuItemStyled>
  );
};

export default SecondMenuItem;
