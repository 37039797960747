import React, { FC, useState } from 'react';
import Slider from 'react-slick';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import { useAppSelector } from 'application/hooks/redux';
import { PersonIcon, SearchPlusIcon } from 'application/assets';

import GalleryPreviewModal from '../GalleryPreviewModal';

import { ArrowProps } from './models';
import {
  Wrapper,
  AvatarContainer,
  IconButtonWrapper,
  ImageContainer,
  ImageOverlay,
  MainImageContainer,
  MainPhotoWrapper,
  VerticalCarousel,
  CustomArrow,
} from './styles';

import 'slick-carousel/slick/slick.css';

const DoctorGallery: FC = () => {
  const { profile } = useAppSelector((state) => state.hrDepartmentSearch);

  const [showModal, setShowModal] = useState(false);
  const [activePhotoIdx, setActivePhotoIdx] = useState(0);

  const SampleNextArrow: FC<ArrowProps> = ({ className, onClick }) => {
    return (
      <CustomArrow className={className} onClick={onClick}>
        <ChevronRight width={12} height={12} />
      </CustomArrow>
    );
  };

  const SamplePrevArrow: FC<ArrowProps> = ({ className, onClick }) => {
    return (
      <CustomArrow className={className} onClick={onClick}>
        <ChevronLeft width={12} height={12} />
      </CustomArrow>
    );
  };

  const settings = {
    vertical: true,
    verticalSwiping: true,
    infinite: true,
    slidesToShow:
      profile?.photos && profile?.photos?.length < 5
        ? profile?.photos.length
        : 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Wrapper>
      {profile?.photos && profile?.photos.length > 1 && (
        <VerticalCarousel showDots={profile?.photos.length > 5}>
          <Slider {...settings}>
            {profile?.photos.map((photo, index) => (
              <ImageContainer
                key={index}
                onClick={() => setActivePhotoIdx(index)}
              >
                <img
                  src={photo.thumbnailUrl}
                  alt={`img ${index}`}
                  width={64}
                  height={64}
                />
              </ImageContainer>
            ))}
          </Slider>
        </VerticalCarousel>
      )}

      <MainPhotoWrapper>
        {profile?.photos &&
        profile?.photos[activePhotoIdx] &&
        profile?.photos[activePhotoIdx].photoUrl ? (
          <MainImageContainer onClick={() => setShowModal(true)}>
            <img
              src={
                profile?.photos[activePhotoIdx].photoUrl || '/assets/person.svg'
              }
              alt={profile?.photos[activePhotoIdx].key || 'main photo'}
              width={338}
              height={338}
            />

            <ImageOverlay>
              <IconButtonWrapper>
                <SearchPlusIcon width={72} height={72} />
              </IconButtonWrapper>
            </ImageOverlay>
          </MainImageContainer>
        ) : (
          <AvatarContainer>
            <PersonIcon width={168} height={168} />
          </AvatarContainer>
        )}
      </MainPhotoWrapper>
      <GalleryPreviewModal
        isOpenModal={showModal}
        onCloseModal={() => setShowModal(false)}
      />
    </Wrapper>
  );
};

export default DoctorGallery;
