import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronRight, Person } from '@mui/icons-material';
import dayjs from 'dayjs';

import {
  GroupsIcon,
  HomeWorkIcon,
  IdCardIcon,
  ScheduleIcon,
  VideoCameraIcon,
  WalletIcon,
} from 'application/assets';

import { PurchasedSubscriptionStatus } from 'features/doctor-subscription/constants';

import { SubscriptionCardProps } from './models';
import {
  Wrapper,
  TopSection,
  PhotoStyled,
  AvatarStyled,
  NameStyled,
  StatusWrapper,
  StatusStyled,
  BottomSection,
  SubscriptionInfo,
  ButtonSection,
  CancelButton,
  ButtonStyled,
} from './styles';

const SubscriptionCard: FC<SubscriptionCardProps> = ({
  subscription,
  onSeeEmployees,
  onDelete,
}) => {
  const { t, i18n } = useTranslation();

  const genderLabel =
    subscription.professional?.gender === 'Male'
      ? `${t('global.drMale')}`
      : `${t('global.drFemale')}`;
  const professionalFullName = `${genderLabel} ${subscription.professional?.firstName} ${subscription.professional?.lastName}`;

  const isSubscriptionActive =
    subscription.status === PurchasedSubscriptionStatus.Active;
  const subscriptionCanceled = !!subscription.cancellationDate;
  const shouldShowSubscriptionPeriodEnd =
    dayjs(subscription.currentPeriodEnd).isAfter(dayjs()) &&
    subscriptionCanceled;
  const subscriptionPeriodEnd = dayjs(subscription.currentPeriodEnd)
    .locale(i18n.language)
    .format('MMMM DD, YYYY');
  const pricePerEmployee = (
    Number(subscription?.clinicProfessionalSubscription?.price) / 100
  ).toFixed(0);
  const totalPaid = (Number(subscription.totalPaid) / 100).toFixed(0);
  const durationInWeeks = Number(
    subscription?.clinicProfessionalSubscription?.durationInWeeks || 0,
  );
  const durationPeriod =
    durationInWeeks >= 4
      ? t('global.month')
      : t(Number(durationInWeeks) > 1 ? 'global.weeks' : 'global.week');

  return (
    <Wrapper>
      <TopSection>
        <PhotoStyled>
          {subscription.professional?.mainPhoto ? (
            <img
              src={subscription.professional?.mainPhoto.thumbnailUrl}
              alt={subscription.professional?.mainPhoto.id}
            />
          ) : (
            <AvatarStyled>
              <Person />
            </AvatarStyled>
          )}
        </PhotoStyled>
        <NameStyled>
          <h1>{professionalFullName}</h1>
        </NameStyled>
        <StatusWrapper>
          {shouldShowSubscriptionPeriodEnd && (
            <p>
              {t('doctor_subscription.active_until')} {subscriptionPeriodEnd}
            </p>
          )}
          <StatusStyled
            status={
              subscriptionCanceled
                ? PurchasedSubscriptionStatus.Canceled
                : subscription.status
            }
          >
            <p>
              {t(
                !subscriptionCanceled && isSubscriptionActive
                  ? 'global.active'
                  : 'global.canceled',
              )}
            </p>
          </StatusStyled>
        </StatusWrapper>
      </TopSection>
      <BottomSection>
        <SubscriptionInfo>
          <div>
            <IdCardIcon className="stroke" />
            <h2>{t('doctor_subscription.name_of_subscription')}:</h2>
            <p>{subscription?.clinicProfessionalSubscription?.title}</p>
          </div>
          <div>
            <ScheduleIcon />
            <h2>{t('global.duration')}:</h2>
            <p>
              {subscription?.clinicProfessionalSubscription?.durationInWeeks}{' '}
              {durationPeriod}
            </p>
          </div>
          <div>
            <HomeWorkIcon />
            <h2>{t('global.clinic')}:</h2>
            <p>{subscription?.clinicProfessionalSubscription?.clinicName}</p>
          </div>
          <div>
            <VideoCameraIcon />
            <h2>{t('subscription_payment.teleconsultation_per_employee')}:</h2>
            <p>
              {
                subscription?.clinicProfessionalSubscription
                  ?.teleconsultationMinutes
              }{' '}
              {t('global.min')}
            </p>
          </div>
          <div>
            <GroupsIcon />
            <h2>{t('global.employee_range')}:</h2>
            <p>{`${
              subscription?.clinicProfessionalSubscription?.minNumberOfEmployees
            } - ${
              subscription?.clinicProfessionalSubscription?.maxNumberOfEmployees
            } ${t('global.employees')}`}</p>
          </div>
          <div>
            <WalletIcon />
            <h2>{t('global.price_per_employee')}:</h2>
            <p>€{pricePerEmployee}</p>
          </div>
          <div>
            <GroupsIcon />
            <h2>{t('global.assigned_employees')}:</h2>
            <p>{`${subscription.seatsPurchased} ${t('global.employees')}`}</p>
          </div>
          <div>
            <WalletIcon />
            <h2>{t('global.total_price')}:</h2>
            <p>€{totalPaid}</p>
          </div>
        </SubscriptionInfo>
        <ButtonSection>
          {!subscriptionCanceled && isSubscriptionActive && (
            <CancelButton type="button" onClick={() => onDelete(subscription)}>
              {t('doctor_subscription.cancel_subscription')}
            </CancelButton>
          )}
          <ButtonStyled
            type="button"
            onClick={() => onSeeEmployees(subscription.id)}
          >
            <p>{t('doctor_subscription.see_list_of_employees')}</p>
            <ChevronRight />
          </ButtonStyled>
        </ButtonSection>
      </BottomSection>
    </Wrapper>
  );
};

export default SubscriptionCard;
