import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from 'application/components';
import { PaymentProgressIcon } from 'application/assets';

import { PaymentSuccessScreenProps } from './module';
import { Wrapper } from './styles';

const PaymentSuccessScreen: FC<PaymentSuccessScreenProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <PaymentProgressIcon />
      <h2>{t('subscription_payment.payment_progress.title')}</h2>
      <h3>{t('subscription_payment.payment_progress.sub_title')}</h3>
      <PrimaryButton type="button" onClick={onSubmit}>
        {t('global.got_it')}
      </PrimaryButton>
    </Wrapper>
  );
};

export default PaymentSuccessScreen;
