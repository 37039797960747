import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { Pagination } from 'application/components';
import { requestSelector } from 'application/store/reducers/request/selectors';
import { HrDepartmentSearchActions } from 'application/store/reducers/HrDepartmentSearch/actions';

import DoctorCard from '../DoctorCard';
import EmptyState from '../EmptyState';

import { Wrapper, SortSection, ListStyled } from './styles';

const DoctorsList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { professionals, total, page, totalPages, filters, limit } =
    useAppSelector((state) => state.hrDepartmentSearch);
  const { fetchHrDepartmentSearch } = useAppSelector(requestSelector);

  // TODO temporary hidden
  // const { sortBy, setSortBy, sortOptions } = useSort();

  const isLoading = fetchHrDepartmentSearch.loading;

  const results = useMemo(() => {
    return `${total} ${t(`global.${total === 1 ? 'result' : 'results'}`)}`;
  }, [total, t]);

  const handleChangePage = async (currentPage: number) => {
    if (filters) {
      const {
        specializationId,
        minNumberOfEmployees,
        maxNumberOfEmployees,
        location,
      } = filters;

      await dispatch(
        HrDepartmentSearchActions.fetchHrDepartmentSearch({
          specializationId: specializationId,
          location: {
            countryCode: location?.countryCode,
            latitude: location?.latitude,
            longitude: location?.longitude,
            city: location?.city || '',
          },
          ...(!!minNumberOfEmployees ? { minNumberOfEmployees } : {}),
          ...(!!maxNumberOfEmployees ? { maxNumberOfEmployees } : {}),
          page: currentPage,
          limit,
        }),
      );
    }
  };

  return (
    <Wrapper>
      <SortSection>
        <h2>{results}</h2>
        {/*TODO temporary hidden*/}
        {/* <Dropdown
          id="sort"
          placeholder={t('Sort by price:')}
          value={sortBy}
          onChange={(option) => {
            if (!Array.isArray(option)) {
              setSortBy(option.value as string);
            }
          }}
          options={sortOptions}
        /> */}
      </SortSection>
      {total > 0 ? (
        <ListStyled>
          {professionals.map((professional) => (
            <DoctorCard key={professional.id} professional={professional} />
          ))}
        </ListStyled>
      ) : (
        <EmptyState />
      )}
      {!isLoading && total > 10 ? (
        <Pagination
          pages={totalPages}
          setCurrentPage={handleChangePage}
          currentPage={page}
          itemCount={total}
        />
      ) : (
        ''
      )}
    </Wrapper>
  );
};

export default DoctorsList;
