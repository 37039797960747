import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: inherit;
  padding: 32px 0 12px;

  > h1 {
    color: ${commonColors.grey900};
    text-align: left;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: capitalize;
  }
`;
