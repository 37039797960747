import { createAsyncThunk } from '@reduxjs/toolkit';

import { RequestEnum } from 'application/store/reducers/request/model';

import { SubscriptionsAPI } from 'integration/api/subscriptions';

const getSubscriptions = createAsyncThunk(
  RequestEnum.getSubscriptions,
  async (page: number, { rejectWithValue }) => {
    try {
      const { data } = await SubscriptionsAPI.getSubscriptions(page);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getSubscriptionById = createAsyncThunk(
  RequestEnum.getSubscriptionById,
  async (companyPurchasedSubscriptionId: string, { rejectWithValue }) => {
    try {
      const { data } = await SubscriptionsAPI.getSubscriptionById(
        companyPurchasedSubscriptionId,
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const deleteSubscription = createAsyncThunk(
  RequestEnum.deleteSubscription,
  async (companyPurchasedSubscriptionId: string, { rejectWithValue }) => {
    try {
      const { data } = await SubscriptionsAPI.deleteSubscription(
        companyPurchasedSubscriptionId,
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const SubscriptionsActions = {
  getSubscriptions,
  getSubscriptionById,
  deleteSubscription,
};
