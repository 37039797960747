import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface DividerProps {
  color?: string;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  > h1 {
    color: ${commonColors.grey900};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: capitalize;
  }
`;

export const Divider = styled.div<DividerProps>`
  height: 1px;
  width: 100%;
  border-top: 1px solid ${({ color }) => (color ? color : commonColors.grey100)};
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ContentItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;

  > div:last-of-type {
    display: flex;
    flex-direction: column;
    gap: 4px;

    h2 {
      color: ${commonColors.grey600};
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.07px;
    }

    h1 {
      color: ${commonColors.grey900};
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.04px;
    }

    p {
      color: ${commonColors.grey600};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.07px;
    }
  }
`;

export const IconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 100px;
  background: ${commonColors.blue100};

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;

    path {
      fill: ${commonColors.blue800};
    }

    &.small {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
    }

    &.stroke {
      path {
        fill: unset;
        stroke: ${commonColors.blue800};
      }
    }
  }
`;
