import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CompanyPurchasedSubscriptionExtendedResponse } from '@docbay/schemas';

import { InitialState } from './models';
import { SubscriptionsActions } from './actions';

const initialState: InitialState = {
  total: 0,
  page: 1,
  totalPages: 0,
  subscriptions: [],
  subscription: null,
};

const subscriptionsSlice = createSlice({
  name: 'subscriptionsSlice',
  initialState,
  reducers: {
    setSubscription: (
      state,
      action: PayloadAction<CompanyPurchasedSubscriptionExtendedResponse | null>,
    ) => {
      state.subscription = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      SubscriptionsActions.getSubscriptions.fulfilled,
      (state, action) => {
        state.subscriptions = action.payload.entities;
        state.total = action.payload.total || 0;
        state.page = action.payload.page || 1;
        state.totalPages = action.payload.totalPages || 0;
      },
    );
    builder.addCase(
      SubscriptionsActions.getSubscriptionById.fulfilled,
      (state, action) => {
        state.subscription = action.payload;
      },
    );
  },
});

export const { setSubscription } = subscriptionsSlice.actions;
export default subscriptionsSlice.reducer;
