import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';

import { useAppSelector } from 'application/hooks/redux';
import { CloseIcon } from 'application/assets';
import { Pagination } from 'application/components';

import EmployeeItem from '../EmployeeItem';

import { PatientsTableProps } from './models';
import {
  Wrapper,
  HeaderStyled,
  Content,
  PackStyled,
  TableStyled,
  TableWrapper,
} from './styles';

const limit = 6;

const EmployeesTable: FC<PatientsTableProps> = ({ opened, onClose }) => {
  const { t } = useTranslation();
  const { subscription } = useAppSelector((state) => state.subscriptions);

  const [page, setPage] = useState(1);

  const total = subscription?.employees.length || 0;
  const totalPages = Math.ceil(
    Number(subscription?.employees.length || 0) / limit,
  );

  const employees = useMemo(() => {
    if (!subscription?.employees.length) return [];
    return subscription?.employees.slice((page - 1) * limit, limit * page);
  }, [subscription?.employees, page]);

  const employeesRange = `${
    subscription?.clinicProfessionalSubscription?.minNumberOfEmployees
  } - ${subscription?.clinicProfessionalSubscription?.maxNumberOfEmployees} ${t(
    'global.employees',
  )}`;
  const pricePerEmployee = (
    Number(subscription?.clinicProfessionalSubscription?.price || 0) / 100
  ).toFixed(0);
  const durationInWeeks = Number(
    subscription?.clinicProfessionalSubscription?.durationInWeeks || 0,
  );
  const durationPeriod =
    durationInWeeks >= 4
      ? t('global.month')
      : t(Number(durationInWeeks) > 1 ? 'global.weeks' : 'global.week');

  const handleChangePage = (value: number) => {
    setPage(value);
  };

  return (
    <Modal open={opened}>
      <Wrapper>
        <HeaderStyled>
          <h1>{t('doctor_subscription.list_of_assigned_employees')}</h1>
          <button type="button" onClick={onClose}>
            <CloseIcon />
          </button>
        </HeaderStyled>
        <Content>
          <PackStyled>
            <h2>{subscription?.clinicProfessionalSubscription?.title}:</h2>
            <p>{`(${employeesRange}, €${pricePerEmployee}/${t(
              'global.employee',
            )}/${durationPeriod}).`}</p>
            <h3>
              {t('global.assigned_employees')}: {subscription?.seatsPurchased}
            </h3>
          </PackStyled>
          <TableWrapper>
            <TableStyled>
              <thead>
                <tr>
                  <th>
                    <span>{t('staff.employees_table.name')}</span>
                  </th>
                  <th>
                    <span>{t('staff.employees_table.phone')}</span>
                  </th>
                  <th>
                    <span>{t('staff.employees_table.department')}</span>
                  </th>
                  <th>
                    <span>{t('staff.employees_table.position')}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {employees.map((employee) => (
                  <EmployeeItem key={employee.id} employee={employee} />
                ))}
              </tbody>
            </TableStyled>
            {total > limit && (
              <Pagination
                pages={totalPages}
                setCurrentPage={handleChangePage}
                currentPage={page}
                itemCount={total}
              />
            )}
          </TableWrapper>
        </Content>
      </Wrapper>
    </Modal>
  );
};

export default EmployeesTable;
