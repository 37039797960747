import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { EmployeesModalT } from '../types/employeesModal';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const useEmployeeModal = () => {
  const { t } = useTranslation();

  const requiredError = t('errors.required') || '';
  const phoneError = t('errors.phone_invalid') || '';
  const lengthError = t('errors.at_least_2_characters') || '';

  const employeeModalSchema = yup
    .object({
      firstName: yup.string().required(requiredError).min(2, lengthError),
      lastName: yup.string().required(requiredError).min(2, lengthError),
      dateOfBirth: yup.string().required(requiredError),
      departmentId: yup.string(),
      jobPosition: yup.string().required(requiredError),
      phone: yup.string().test({
        name: 'is-valid',
        test(value, ctx) {
          const isValidNumber = isValidPhoneNumber(`+${String(value)}`);
          if (!isValidNumber)
            return ctx.createError({
              message: phoneError,
            });
          return isValidNumber;
        },
      }),
      insuranceNumber: yup.string().test({
        name: 'is-valid',
        test(value, ctx) {
          if (value?.length === 1)
            return ctx.createError({
              message: lengthError,
            });
          return true;
        },
      }),
    })
    .required();

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    formState: { errors },
  } = useForm<EmployeesModalT>({
    resolver: yupResolver(employeeModalSchema),
  });

  return {
    register,
    watch,
    getValues,
    setValue,
    errors,
    handleSubmit,
    clearErrors,
    setError,
    reset,
  };
};
