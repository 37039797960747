import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { HrDepartmentDashboardRequestDto } from '@docbay/schemas';

import { DashboardFiltersDepartmentsParams } from 'application/constants/dashboard';

import { DashboardAPI } from 'integration/api/dashboard';

import { RequestEnum } from '../request/model';

const getDashboardEmployees = createAsyncThunk(
  RequestEnum.getDashboardEmployees,
  async (params: HrDepartmentDashboardRequestDto, { rejectWithValue }) => {
    try {
      const { data } = await DashboardAPI.getDashboardEmployees(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

const getDashboardFiltersClinics = createAsyncThunk(
  RequestEnum.getDashboardFiltersClinics,
  async (search: string, { rejectWithValue }) => {
    try {
      const { data } = await DashboardAPI.getDashboardFiltersClinics(search);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

const getDashboardFiltersProfessionals = createAsyncThunk(
  RequestEnum.getDashboardFiltersProfessionals,
  async (search: string, { rejectWithValue }) => {
    try {
      const { data } = await DashboardAPI.getDashboardFiltersProfessionals(
        search,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

const getDashboardFiltersDepartments = createAsyncThunk(
  RequestEnum.getDashboardFiltersDepartments,
  async (params: DashboardFiltersDepartmentsParams, { rejectWithValue }) => {
    try {
      const { data } = await DashboardAPI.getDashboardFiltersDepartments(
        params,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

const getDashboardFiltersLocations = createAsyncThunk(
  RequestEnum.getDashboardFiltersLocations,
  async (search: string, { rejectWithValue }) => {
    try {
      const { data } = await DashboardAPI.getDashboardFiltersLocations(search);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const DashboardActions = {
  getDashboardEmployees,
  getDashboardFiltersClinics,
  getDashboardFiltersProfessionals,
  getDashboardFiltersDepartments,
  getDashboardFiltersLocations,
};
