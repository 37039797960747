import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const FormStyled = styled.form`
  display: grid;
  width: 100%;
`;

export const PaymentSection = styled.div`
  border-radius: 12px;
  border: 1px solid ${commonColors.grey200};
  background: ${commonColors.grey50};
  padding: 20px;

  > div#payment-element {
    padding: 0;
    border: 0;
    background: transparent;
  }
`;

export const TitleStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;

  h1 {
    color: ${commonColors.grey900};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25.2px;
    letter-spacing: 0.045px;
  }

  button {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${commonColors.grey600};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22.4px;
    letter-spacing: 0.08px;

    svg {
      width: 24px;
      height: 24px;
      margin-left: 4px;

      path {
        fill: ${commonColors.grey700};
      }
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 16px;
`;

export const AddPaymentButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${commonColors.blue800};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  letter-spacing: 0.04px;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 4px;

    path {
      fill: ${commonColors.blue800};
    }
  }
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    color: ${commonColors.grey700};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`;
