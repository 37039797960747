import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface AmountProps {
  fontWeight?: number;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  > h1 {
    color: ${commonColors.grey900};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;

export const TotalSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${commonColors.grey300};
  background: ${commonColors.white};
`;

export const Amount = styled.div<AmountProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    color: ${commonColors.grey800};
    font-size: 14px;
    font-style: normal;
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 500)};
    line-height: 140%;
    letter-spacing: 0.07px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  p {
    color: ${commonColors.grey500};
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.03px;
  }
`;
