import {
  PaginatedProfessionalHrDepartmentSearchCriteriaDto,
  QuickSearchQueryDto,
} from '@docbay/schemas';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RequestEnum } from 'application/store/reducers/request/model';

import { HrDepartmentSearchAPI } from 'integration/api/hrDepartmentSearch';

const fetchHrDepartmentSearch = createAsyncThunk(
  RequestEnum.fetchHrDepartmentSearch,
  async (
    params: PaginatedProfessionalHrDepartmentSearchCriteriaDto,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await HrDepartmentSearchAPI.fetchHrDepartmentSearch(
        params,
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getHrDepartmentSearchProfessional = createAsyncThunk(
  RequestEnum.getHrDepartmentSearchProfessional,
  async (professionalId: string, { rejectWithValue }) => {
    try {
      const { data } =
        await HrDepartmentSearchAPI.getHrDepartmentSearchProfessional(
          professionalId,
        );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const quickSearch = createAsyncThunk(
  RequestEnum.quickSearch,
  async (params: QuickSearchQueryDto, { rejectWithValue }) => {
    try {
      const { data } = await HrDepartmentSearchAPI.quickSearch(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const HrDepartmentSearchActions = {
  fetchHrDepartmentSearch,
  getHrDepartmentSearchProfessional,
  quickSearch,
};
