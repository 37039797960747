export type RequestState = {
  [name in RequestEnum]: RequestData;
};

export interface RequestData {
  loading: boolean;
  error: string;
}

export enum RequestEnum {
  //company registration
  companySignUp = 'companySignUp',
  companyConfirmSingUp = 'companyConfirmSingUp',
  resendConfirmationCode = 'resendConfirmationCode',
  //users
  usersLogin = 'usersLogin',
  usersRefreshToken = 'usersRefreshToken',
  usersForgotPassword = 'usersForgotPassword',
  usersVerificationCode = 'usersVerificationCode',
  usersCreateNewPassword = 'usersCreateNewPassword',
  signOut = 'signOut',
  setUserLanguage = 'setUserLanguage',
  usersVerifyCode = 'usersVerifyCode',
  fetchUserProfile = 'fetchUserProfile',
  //locations
  getLocations = 'getLocations',
  getLocationById = 'getLocationById',
  addLocation = 'addLocation',
  updateLocation = 'updateLocation',
  deleteLocation = 'deleteLocation',
  //department
  getDepartmentsByCompany = 'getDepartmentsByCompany',
  createDepartment = 'createDepartment',
  deleteDepartment = 'deleteDepartment',
  //employees
  createEmployee = 'createEmployee',
  fetchEmployees = 'fetchEmployees',
  editEmployee = 'editEmployee',
  deleteEmployee = 'deleteEmployee',
  editDepartment = 'editDepartment',
  fetchEmployeeById = 'fetchEmployeeById',
  //hr
  createHr = 'createHr',
  createAdmin = 'createAdmin',
  fetchHrUsers = 'fetchHrUsers',
  fetchHrs = 'fetchHrs',
  editHr = 'editHr',
  editAdmin = 'editAdmin',
  deleteHr = 'deleteHr',
  deleteAdmin = 'deleteAdmin',
  //mapbox
  fetchLocation = 'fetchLocation',
  getCoordinates = 'getCoordinates',
  getLocationByCoordinate = 'getLocationByCoordinate',
  //country
  getSupportedCountries = 'getSupportedCountries',
  setUserCountry = 'setUserCountry',
  //hr department search actions
  fetchHrDepartmentSearch = 'fetchHrDepartmentSearch',
  getHrDepartmentSearchProfessional = 'getHrDepartmentSearchProfessional',
  quickSearch = 'quickSearch',
  //specializations
  fetchSpecializations = 'fetchSpecializations',
  //professional
  subscriptionPayment = 'subscriptionPayment',
  fetchProfessionalSubscriptionById = 'fetchProfessionalSubscriptionById',
  fetchSubscriptionPaymentMethods = 'fetchSubscriptionPaymentMethods',
  //dashboard
  getDashboardEmployees = 'getDashboardEmployees',
  getDashboardFiltersClinics = 'getDashboardFiltersClinics',
  getDashboardFiltersProfessionals = 'getDashboardFiltersProfessionals',
  getDashboardFiltersDepartments = 'getDashboardFiltersDepartments',
  getDashboardFiltersLocations = 'getDashboardFiltersLocations',
  //subscriptions
  getSubscriptions = 'getSubscriptions',
  getSubscriptionById = 'getSubscriptionById',
  deleteSubscription = 'deleteSubscription',
}
