import React, { FC } from 'react';
import { Modal } from '@mui/material';

import { CloseIcon, UnsuccessIcon } from 'application/assets';

import SecondaryButton from '../SecondaryButton';

import { ErrorModalProps } from './modules';
import { StyledModal } from './styles';

const ErrorModal: FC<ErrorModalProps> = ({
  isOpen,
  onClose,
  text,
  description,
  buttonText,
}) => {
  return (
    <Modal open={isOpen}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <UnsuccessIcon />
          <p>{text}</p>
          {description && <div>{description}</div>}
          <SecondaryButton type="button" onClick={onClose}>
            {buttonText}
          </SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default ErrorModal;
