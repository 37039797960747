import { ProfessionalActions } from 'application/store/reducers/Professional/actions';
import { useAppDispatch, useAppSelector } from './redux';

export const useSubscriptionPaymentMethods = () => {
  const dispatch = useAppDispatch();
  const { paymentMethods } = useAppSelector((store) => store.professional);

  const getPaymentMethods = async (stripeAccountId: string) => {
    await dispatch(
      ProfessionalActions.fetchSubscriptionPaymentMethods(stripeAccountId),
    );
  };

  return {
    getPaymentMethods,
    paymentMethods,
  };
};
